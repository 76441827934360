import { codeRunner, validationOperations } from '@adsk/informed-design-code-runner';
import {
  ProductDefinition,
  ProductDefinitionInputParameter,
  createFullPath,
  getPartOrAssemblyProperties,
} from 'mid-addin-lib';
import { NOTIFICATION_STATUSES, NotificationContext, StateSetter } from '@mid-react-common/common';
import {
  CreateProductDefinitionError,
  ErrorCode,
  ForgeValidationError,
  isBooleanInput,
  isNumericInput,
  isTextInput,
  logError,
} from 'mid-utils';
import { useCallback, useContext, useEffect, useState, MutableRefObject, useRef } from 'react';
import text from '../../../inventor.text.json';
import { getNotificationBody } from '../../../utils/productDefinition';
import TabProgressContext from 'context/TabProgressStore/TabProgress.context';
import { DCInput } from '@adsk/offsite-dc-sdk';
import { formRulesKey } from '../FormCodeblocks/FormCodeblocks.constants';
import { FormRules } from 'mid-types';
import { isUndefined } from 'lodash';
import { productDefinitionActions, useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { useShallow } from 'zustand/react/shallow';
import { updateModelAndProductDefinitionInputs } from './useProductFormPreview.utils';

interface useProductFormPreviewProps {
  showMessageDialog: (messages: string[]) => void;
  setUpdateFormEnabled: StateSetter<boolean>;
  setHighlightedBlockId: (faultyBlockId: string | undefined) => void;
  areRulesLoaded: boolean;
  getCodeRef: MutableRefObject<(() => string) | undefined>;
}

interface useProductFormPreviewReturn {
  handleSetModelValues: () => void;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  handleResetToDefaults: () => void;
  handleGetModelValues: () => void;
  handleUpdateForm: () => Promise<void>;
  inputsError: ForgeValidationError | undefined;
  currentFormRules?: FormRules;
  isFormLoading: boolean;
}

export const useProductFormPreview = ({
  setHighlightedBlockId,
  showMessageDialog,
  setUpdateFormEnabled,
  areRulesLoaded,
  getCodeRef,
}: useProductFormPreviewProps): useProductFormPreviewReturn => {
  const {
    currentProductDefinitionId,
    currentProductDefinitionParametersDefaults,
    currentProductDefinitionTopLevelFolder,
    currentProductDefinitionAssembly,
  } = useProductDefinitionStore(
    useShallow((state) => ({
      currentProductDefinitionId: state.id,
      currentProductDefinitionParametersDefaults: state.parametersDefaults,
      currentProductDefinitionTopLevelFolder: state.topLevelFolder,
      currentProductDefinitionAssembly: state.assembly,
    })),
  );

  const { setHasInputsError } = useContext(TabProgressContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);
  const [inputsError, setInputsError] = useState<ForgeValidationError | undefined>();
  const [runRulesOnInitialInputs, setRunRulesOnInitialInputs] = useState(true);
  const [currentFormRules, setCurrentFormRules] = useState<FormRules | undefined>();

  const rulesRef = useRef(useProductDefinitionStore.getState().rules);
  const inputsRef = useRef(useProductDefinitionStore.getState().inputs);

  // We use a reference because we don't want to react when these change
  useEffect(() => {
    useProductDefinitionStore.subscribe((state) => {
      rulesRef.current = state.rules;
      inputsRef.current = state.inputs;
    });
  }, []);

  const applyRulesToProductDefinitionInputs = useCallback(
    async (formInputs: ProductDefinitionInputParameter[]) => {
      if (!areRulesLoaded || !getCodeRef.current) {
        return;
      }

      // TODO: TRADES-6678 extend codeRunner function to derive the result type from the inputs type
      const {
        error,
        result: productDefinitionInputs,
        faultyBlockId,
      } = await codeRunner({
        code: getCodeRef.current() || '',
        inputs: formInputs,
        printCallback: showMessageDialog,
      });

      setHighlightedBlockId(faultyBlockId);

      // We only show errors that are related to the code blocks, not validation errors
      if (error && error.errorCode === ErrorCode.CodeRunnerError) {
        logAndShowNotification({
          message: error.message,
          severity: NOTIFICATION_STATUSES.ERROR,
          messageBody: getNotificationBody(error.errors.map((error) => error.detail)),
        });
      }

      // This just adds the inputs to the current product definition in the
      // dataStore, the user will lose his changes if he closes the addin & returns
      // The user has a "save" button where they can update the product definition
      productDefinitionActions.replaceAllInputs(productDefinitionInputs);

      setInputsError(error || undefined);

      // Disable on run time errors and type validation errors
      const inputsErrorExists =
        error &&
        (error.errorCode === ErrorCode.CodeRunnerError ||
          error.errors.some((e) => e.operation === validationOperations.TYPE));

      setHasInputsError(!!inputsErrorExists);

      // If the user has saved the form rules, we need to update the form rules
      const currentFormRules = rulesRef.current.find((rule) => rule.key === formRulesKey);
      if (currentFormRules) {
        try {
          const parsedFormRules: FormRules = JSON.parse(currentFormRules.code);
          setCurrentFormRules(JSON.parse(currentFormRules.code));
          if (parsedFormRules.inputs.length <= 0) {
            setHasInputsError(true);
          }
        } catch (e) {
          setHasInputsError(true);
          showNotification({
            message: text.blocklyFormRulesAreInvalid,
            severity: NOTIFICATION_STATUSES.ERROR,
          });
        }
      }
    },
    [
      areRulesLoaded,
      getCodeRef,
      showMessageDialog,
      setHighlightedBlockId,
      setHasInputsError,
      logAndShowNotification,
      showNotification,
    ],
  );

  useEffect(() => {
    if (runRulesOnInitialInputs && areRulesLoaded) {
      applyRulesToProductDefinitionInputs(inputsRef.current);
      setRunRulesOnInitialInputs(false);
    }
  }, [runRulesOnInitialInputs, areRulesLoaded, applyRulesToProductDefinitionInputs]);

  // Base Model gets values from the Form Preview
  const handleSetModelValues = async (): Promise<void> => {
    try {
      const currentProductDefinition = useProductDefinitionStore.getState();

      const upsertedProductDefinition = await updateModelAndProductDefinitionInputs(currentProductDefinition);

      // update the current product definition in case user clicked the Set Model Values and the product definition
      // hasn't been saved before, this avoids double creation during the next click
      if (currentProductDefinitionId !== upsertedProductDefinition.id) {
        productDefinitionActions.setProductDefinition(upsertedProductDefinition);
      }

      showNotification({
        message: text.inventorModelSuccessfullyUpdated,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err: unknown) {
      if (err instanceof CreateProductDefinitionError) {
        showNotification({
          message: err.message,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        showNotification({
          message: text.inventorModelUpdateFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }

      logError(err);
    }
  };

  const handleInputUpdate = (payload: DCInput): Promise<void> => {
    setUpdateFormEnabled(false);
    const updatedInputs = inputsRef.current.reduce(
      (
        updatedFormData: ProductDefinitionInputParameter[],
        inputRow: ProductDefinitionInputParameter,
      ): ProductDefinitionInputParameter[] => {
        if (inputRow.name === payload.name) {
          return [
            ...updatedFormData,
            {
              ...inputRow,
              value: payload.value,
            } as ProductDefinitionInputParameter,
          ];
        }
        return [...updatedFormData, inputRow];
      },
      [],
    );
    return applyRulesToProductDefinitionInputs(updatedInputs);
  };

  const resetToDefaults = (parameterDefaults: ProductDefinition['parametersDefaults']) => {
    if (!parameterDefaults) {
      return;
    }

    const parameterDefaultsMap = new Map(
      parameterDefaults.map((parameterDefault) => [parameterDefault.name, parameterDefault.value]),
    );

    const updatedInputsWithDefaults = inputsRef.current.map((input): ProductDefinitionInputParameter => {
      const defaultValue = parameterDefaultsMap.get(input.name);

      if (isUndefined(defaultValue)) {
        return input;
      }

      if (isTextInput(input)) {
        return {
          ...input,
          value: String(defaultValue),
        };
      }
      if (isBooleanInput(input)) {
        return {
          ...input,
          value: Boolean(defaultValue),
        };
      }

      if (isNumericInput(input)) {
        return {
          ...input,
          value: Number(defaultValue),
        };
      }

      return input;
    });

    applyRulesToProductDefinitionInputs(updatedInputsWithDefaults);
  };

  // Form Preview gets the values from the Product Definition Defaults
  const handleResetToDefaults = () => {
    resetToDefaults(currentProductDefinitionParametersDefaults);
  };

  // Product Definition Defaults AND Form Preview get values from the Base Model
  const handleGetModelValues = async () => {
    const fullPath = createFullPath(currentProductDefinitionTopLevelFolder, currentProductDefinitionAssembly);

    const inventorData = await getPartOrAssemblyProperties(fullPath);

    const newDefaults = productDefinitionActions.setParametersDefaultsByInventorParameters(inventorData.parameters);

    resetToDefaults(newDefaults);
  };

  // Form Preview gets values processed by the Code Blocks
  const handleUpdateForm = (): Promise<void> => {
    setUpdateFormEnabled(false);
    return applyRulesToProductDefinitionInputs(inputsRef.current);
  };

  return {
    handleSetModelValues,
    handleResetToDefaults,
    handleGetModelValues,
    handleInputUpdate,
    handleUpdateForm,
    inputsError,
    currentFormRules,
    isFormLoading: runRulesOnInitialInputs,
  };
};
