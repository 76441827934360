import Blockly from 'blockly';
import text from 'inventor.text.json';
import { BaseBlock, BlocklyVariable, BlocklyWorkspace, isFunctionBlock, isFunctionBlockWithArgs } from '../../types';

const nameWithNumberRegEx = /(.*?)(\d+)$/;

// the bottom most block Y has to be grabbed from the bounding rectangle of the SVG blog, because the vertical
// positioning of the block is a kind of relative (depends on the vertical scroll value), the normal block.y won't help
export const findBottomMostBlockY = (blocks: Blockly.BlockSvg[]): number => {
  if (!blocks.length) {
    return 0;
  }

  return Math.max(...blocks.map((block) => block.getBoundingRectangle().bottom));
};

export const findLeftMostBlockX = (blocks: BaseBlock[]): number => {
  if (!blocks.length) {
    return 0;
  }

  return Math.min(...blocks.map((block) => block.x));
};

export const findTopMostBlockY = (blocks: BaseBlock[]): number => {
  if (!blocks.length) {
    return 0;
  }

  return Math.min(...blocks.map((block) => block.y));
};

// removes the left and top margin within the blockly canvas to make it possible to align the imported blocks with the
// leftmost and bottommost existing block
export const removeBlocksBaseOffset = (blocks: BaseBlock[]): BaseBlock[] => {
  const topMostBlockY = findTopMostBlockY(blocks);
  const leftMostBlockX = findLeftMostBlockX(blocks);

  blocks.forEach((block) => {
    block.x -= leftMostBlockX;
    block.y -= topMostBlockY;
  });

  return blocks;
};

// generic function which recursively traverses through the deep object/array and changes the value of an arbitrary key
// with the value returned from `mutator` function. The `condition` function decides whether to mutate or not.
export const updatePropInNestedStructure = (
  obj: any,
  condition: (key: string, obj: any) => boolean,
  mutator: (obj: any) => any,
): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => updatePropInNestedStructure(item, condition, mutator));
  }

  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (condition(key, obj)) {
        obj[key] = mutator(obj[key]);
      } else {
        obj[key] = updatePropInNestedStructure(obj[key], condition, mutator);
      }
    });
  }

  return obj;
};

export const adoptItemName = (
  currentItemsNames: string[],
  adoptedItemsNames: string[],
  oldItemNameNoSuffix: string,
  oldItemName: string,
): string => {
  const currentAndAdoptedItemsNames = [...currentItemsNames, ...adoptedItemsNames];

  // do nothing if the imported name has no current duplicates
  if (!currentAndAdoptedItemsNames.includes(oldItemName)) {
    return oldItemName;
  }

  // check if there are multiple items with the duplicated name
  const filteredCurrentItemsNames = currentAndAdoptedItemsNames.filter((currentItemName) => {
    // do not include the item name without a numeric suffix
    if (!nameWithNumberRegEx.test(currentItemName)) {
      return false;
    }

    const currentItemNameNoSuffix = currentItemName.replace(nameWithNumberRegEx, '$1');
    return currentItemNameNoSuffix === oldItemNameNoSuffix;
  });

  if (filteredCurrentItemsNames.length === 0) {
    if (nameWithNumberRegEx.test(oldItemName)) {
      // check if an item already contains a numeric suffix
      const numericSuffix = oldItemName.match(nameWithNumberRegEx)!.at(2)!;

      // set the new item name with an increment
      return oldItemNameNoSuffix + (parseInt(numericSuffix) + 1);
    }

    // there is no numeric suffix, just add 1 at the end
    return oldItemName + 1;
  }

  // extract numeric suffixes
  const currentItemsSuffixes = filteredCurrentItemsNames.map((filteredCurrentItemName) => {
    // the filteredCurrentItemName has already been tested against the regex, tell TS that array is not empty
    const numericSuffix = filteredCurrentItemName.match(nameWithNumberRegEx)!.at(-1)!;
    return parseInt(numericSuffix);
  });

  currentItemsSuffixes.sort((a, b) => a - b);
  const highestSuffix = currentItemsSuffixes.at(-1)!;

  return oldItemNameNoSuffix + (highestSuffix + 1);
};

export const adoptVariables = (currentVars: BlocklyVariable[], blocksToInsert: BlocklyWorkspace): BaseBlock[] => {
  const importedVars = blocksToInsert.variables;
  let importedBlocks = blocksToInsert.blocks.blocks;
  const currentVarNames: string[] = currentVars.map((blockVar) => blockVar.name);

  const adoptedVarNames: string[] = [];

  importedVars.forEach((importedVar) => {
    const oldVarName = importedVar.name;
    const oldVarNameNoSuffix = oldVarName.replace(nameWithNumberRegEx, '$1');

    const newVarName = adoptItemName(currentVarNames, adoptedVarNames, oldVarNameNoSuffix, oldVarName);

    if (newVarName === oldVarName) {
      return;
    }

    // generate the brand-new id for the var
    const newVarId = Blockly.utils.idGenerator.genUid();

    // update all occurrences of the old variable name in functions
    importedBlocks = updateImportedVariableInFunctions(importedBlocks, oldVarName, newVarName, newVarId);

    // update all occurrences of the old variable id's everywhere
    importedBlocks = updatePropInNestedStructure(
      importedBlocks,
      (key, obj: Record<string, string>) => key === 'id' && obj[key] === importedVar.id,
      () => newVarId,
    );

    // update all occurrences of the old variable names everywhere
    importedBlocks = updatePropInNestedStructure(
      importedBlocks,
      (key, obj: Record<string, string>) => key === 'name' && obj[key] === oldVarName,
      () => newVarName,
    );

    // update the id/name props in the var block
    importedVar.id = newVarId;
    importedVar.name = newVarName;

    // push a new var name to avoid re-duplication (when the variable gets a new name with incremented suffix, but there
    // is another variable to be imported that has the same name as just adopted variable). E.g:
    // variable to import: var, var1
    // existing variables: var
    // The first imported var is adopted to var1, then the second var1 has to be adopted to var2. Without adding the new
    // var name to the adoptedVarNames, the var1 won't be adopted, because the original array of existing vars
    // doesn't have the var1, so it will be skipped which is incorrect
    adoptedVarNames.push(newVarName);
  });

  return importedBlocks;
};

// if the imported function definitions or calls duplicate names of the existing ones, they have to be renamed by
// adding an incremented numeric suffix to the end of their name
export const adoptFunctions = (currentBlocks: BaseBlock[], blocksWorkspaceToInsert: BlocklyWorkspace): BaseBlock[] => {
  let importedBlocks = blocksWorkspaceToInsert.blocks.blocks;
  const currentFunctions = currentBlocks.filter(isFunctionBlock);

  const currentFunctionsNames = currentFunctions.map((functionBlock) => functionBlock.fields.NAME);

  const importedFunctions = importedBlocks.filter(isFunctionBlock);

  const adoptedFunctionsNames: string[] = [];

  importedFunctions.forEach((importedFunction) => {
    const oldFunctionName = importedFunction.fields.NAME;
    const oldFunctionNameNoSuffix = oldFunctionName.replace(nameWithNumberRegEx, '$1');

    const newFunctionName = adoptItemName(
      currentFunctionsNames,
      adoptedFunctionsNames,
      oldFunctionNameNoSuffix,
      oldFunctionName,
    );

    if (newFunctionName === oldFunctionName) {
      return;
    }

    // update a function definition name
    importedFunction.fields.NAME = newFunctionName;

    // update all function calls based on old names
    importedBlocks = updatePropInNestedStructure(
      importedBlocks,
      (key, obj) => key === 'name' && obj[key] === oldFunctionName,
      () => newFunctionName,
    );

    adoptedFunctionsNames.push(newFunctionName);
  });

  return importedBlocks;
};

// when the duplicated variable name is used as a function argument
const updateImportedVariableInFunctions = (
  importedBlocks: BaseBlock[],
  oldVarName: string,
  newVarName: string,
  newVarId: string,
) => {
  // functions with no arguments don't have extra state
  const functionBlocks = importedBlocks.filter(isFunctionBlockWithArgs);

  functionBlocks.forEach((functionBlock) => {
    const paramToAdopt = functionBlock.extraState.params.find((param) => param.name === oldVarName);
    if (!paramToAdopt) {
      return;
    }

    paramToAdopt.id = newVarId;
    delete functionBlock.fields[paramToAdopt.argId];
    paramToAdopt.argId = Blockly.utils.idGenerator.genUid();
    functionBlock.fields[paramToAdopt.argId] = newVarName;

    importedBlocks = updatePropInNestedStructure(
      importedBlocks,
      (key, obj: Record<string, string[]>) => key === 'params' && obj[key].includes(oldVarName),
      (obj) => obj.map((currentVar: string) => (currentVar === oldVarName ? newVarName : currentVar)),
    );
  });

  return importedBlocks;
};

// adopt all the blocks to import before merging with the current state, this includes:
// - adopt the offset to be relative to the left most and bottom most current block
// - add a comment block that defines the start of the imported blocks
// - adopt variable names if there are duplicated ones (add an incremented suffix at the end)
// - adopt function names if there are duplicated ones (add an incremented suffix at the end)
export const adoptBlocks = (blocksToInsert: BlocklyWorkspace, workspace: Blockly.WorkspaceSvg): BlocklyWorkspace => {
  const currentState = Blockly.serialization.workspaces.save(workspace) as BlocklyWorkspace;

  // offset between the lowest existing block and the group of newly inserted ones
  const gapBetweenBlocks = 30;

  if (!blocksToInsert.variables) {
    blocksToInsert.variables = [];
  }

  if (!blocksToInsert.blocks) {
    blocksToInsert.blocks = { languageVersion: 0, blocks: [] };
  }

  // the exported blocks might have some offset from the 0,0 coordinate. Need to remove it, so it will be at 0,0 (X,Y).
  removeBlocksBaseOffset(blocksToInsert.blocks.blocks);

  if (!currentState.blocks) {
    currentState.blocks = { languageVersion: 0, blocks: [] };
  }

  let bottomMostCurrentY = findBottomMostBlockY(workspace.getTopBlocks(false));
  const leftMostCurrentBlockX = findLeftMostBlockX(currentState.blocks.blocks);

  bottomMostCurrentY += gapBetweenBlocks;

  // comment block to show where the inserted blocks start
  const commentBlock = {
    type: 'comment_block',
    id: Blockly.utils.idGenerator.genUid(),
    x: leftMostCurrentBlockX,
    y: bottomMostCurrentY,
    fields: { comment: text.importedBlocksStartComment },
  };

  // insert the comment block
  currentState.blocks.blocks.push(commentBlock);

  // update the bottom most Y data, since the comment block was inserted
  bottomMostCurrentY += gapBetweenBlocks;

  const newState = currentState;

  if (!newState.variables) {
    newState.variables = [];
  }

  const leftMostInsertedBlocksX = findLeftMostBlockX(blocksToInsert.blocks.blocks);
  const horizontalOffset = leftMostInsertedBlocksX - leftMostCurrentBlockX;

  // make inserted blocks below the lowest block on a canvas
  blocksToInsert.blocks.blocks.forEach((block) => {
    block.y = block.y + bottomMostCurrentY;
    block.x = block.x - horizontalOffset;
  });

  blocksToInsert.blocks.blocks = adoptVariables(currentState.variables, blocksToInsert);

  blocksToInsert.blocks.blocks = adoptFunctions(currentState.blocks.blocks, blocksToInsert);

  // merge imported blocks into the current canvas
  newState.blocks.blocks.push(...blocksToInsert.blocks.blocks);
  // merge imported blocks variables into the current canvas
  newState.variables.push(...blocksToInsert.variables);

  return newState;
};
