import Blockly, { BlockSvg, FieldImage, MenuGenerator } from 'blockly';
import { ProductDefinitionInputParameter } from 'mid-addin-lib';
import { appendInputDropdownsToBlock } from '../utils';
import { midTheme, parameterSymbolSvg } from '@mid-react-common/common';
import { blocklyParameterIcon, customBlocklyExtensions } from './InputCodeblocks.constants';
import { blocklyInputsDropdown } from '../constants';

/*
 * Blockly extensions are used to add functionality to blocks.
 * These extensions are used to display dynamically generated dropdowns
 * from ProductDefinitionInputParameter[].
 */

export const initializeBlocklyExtensions = (
  inputParameters: ProductDefinitionInputParameter[],
  enableApplicableParameter?: boolean,
): void => {
  //When Inputs change, we need to add new dropdown options
  if (Blockly.Extensions.isRegistered(customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION)) {
    Blockly.Extensions.unregister(customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION);
  }

  /*
   *  This extension is used to dynamically generate dropdowns for Inputs and Functions
   *  It also sets the check type for the block based on the selected option.
   *
   *  The inputs dropdown looks like this:
   *
   *     // [string, BlocklyInputParameterDropdownValues]
   *    [
   *      ['inputParameter1', '{name: "inputParameterName1", type: "Numeric"}'],
   *      ...
   *      ['inputParameter2', '{name: "inputParameterNameN", type: "Boolean"}'],
   *    ]
   *
   *
   * Blockly uses the first parameter to display the dropdown option and the second parameter
   * to store the value of the option. In this case, we are storing the name and type of the
   * input parameter for validation purposes.
   */

  Blockly.Extensions.register(customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION, function (this: BlockSvg) {
    const parameterIcon = this.getInput(blocklyParameterIcon);

    parameterIcon?.appendField(
      new FieldImage(parameterSymbolSvg, midTheme.var.blocklyIconSize, midTheme.var.blocklyIconSize),
    );

    // Get the dropdown values from the block
    const inputsDropdown = this.getInput(blocklyInputsDropdown);
    if (inputsDropdown) {
      // Generate the input dropdown values
      const dropdownOptions: MenuGenerator = inputParameters.map((input) => [
        input.name,
        JSON.stringify({ name: input.name, type: input.type }),
      ]);

      const newInputsDropdown = new Blockly.FieldDropdown(dropdownOptions);
      appendInputDropdownsToBlock(this, newInputsDropdown, newInputsDropdown.getValue(), enableApplicableParameter);
    }
  });
};
