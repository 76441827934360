import { AddinContainer, EnvironmentInfo } from '@mid-react-common/addins';
import {
  AppLoadingProgress,
  ConfirmationModal,
  ErrorBoundary,
  ErrorFallback,
  InventorWebComponentMoniker,
  ModalContext,
  NotificationsProvider,
  QueryClientProvider,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  darkTheme,
  lightTheme,
  useModalStore,
  useNotificationStore,
} from '@mid-react-common/common';
import CssBaseline from '@mui/material/CssBaseline';
import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { browserApiService, getCurrentAuthToken, getEnvironment } from 'mid-addin-lib';
import { InversifyTypes, inversifyContainer, registerApiBaseURL, registerAuthHandler, registerEnv } from 'mid-api-services';
import { ServiceConfigMap, ServiceTypes, handleAmplitudeIdentify, setAmplitudeGroup } from 'mid-utils';
import React, { useEffect, useState } from 'react';
import { ampli } from './ampli';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import NavigationContext from './context/NavigationStore/Navigation.context';
import { useNavigationStore } from './context/NavigationStore/navigationStore';
import TabProgressContext from './context/TabProgressStore/TabProgress.context';
import { useTabProgressStore } from './context/TabProgressStore/tabProgressStore';
import { useProductDefinitionStore } from './context/DataStore/productDefinitionStore';
import { useInventorDataStore } from './context/DataStore/InventorDataStore';
import { useUnsavedProductDefinitionChangesTracker } from './context/DataStore/unsavedChangesTracker.utils';
import { useShallow } from 'zustand/react/shallow';

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY_ADDINS || '',
  moniker: InventorWebComponentMoniker,
  appVersion: import.meta.env.VITE_IVTW_VERSION || 'Not provided',
});

const App: React.FC = (): JSX.Element => {
  const { enableMaintenanceMode, enableDarkModeSwitch, inventorWebComponentUnderMaintenance } = useFlags();
  const [dark, setDark] = useState<boolean>(false);

  const { topLevelFolder, inventorProject, assembly, inputs, outputs, rules } = useProductDefinitionStore(
    useShallow((state) => ({
      topLevelFolder: state.topLevelFolder,
      inventorProject: state.inventorProject,
      assembly: state.assembly,
      inputs: state.inputs,
      outputs: state.outputs,
      rules: state.rules,
    })),
  );

  const recentlyAdoptedInputs = useInventorDataStore((state) => state.recentlyAdoptedInputs);
  const navigationStore = useNavigationStore();
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();

  const tabProgressStore = useTabProgressStore({
    topLevelFolder,
    inventorProject,
    assembly,
    inputs,
    outputs,
    rules,
    recentlyAdoptedInputs,
  });
  useUnsavedProductDefinitionChangesTracker();

  const [apiServicesInitialized, setApiServicesInitialized] = useState(false);

  useEffect(() => {
    const registerDependency = async () => {
      const env = await getEnvironment();

      const dcApiBaseURL = await browserApiService.getDcApiUrl();
      const forgeApiBaseUrl = ServiceConfigMap[ServiceTypes.FORGE_API][env];
      const accBridgeBaseUrl = ServiceConfigMap[ServiceTypes.ACC_BRIDGE][env];

      if (!inversifyContainer.isBound(InversifyTypes.Env)) {
        registerEnv(env);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
        registerAuthHandler(() => getCurrentAuthToken());
      }

      if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.DcApiBaseURL, dcApiBaseURL);
      }

      if (!inversifyContainer.isBound(InversifyTypes.ForgeApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.ForgeApiBaseURL, forgeApiBaseUrl.api);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AccBridgeApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.AccBridgeApiBaseURL, accBridgeBaseUrl.api);
      }

      setApiServicesInitialized(true);
    };

    registerDependency();
  }, []);

  const handleResetAppState = (): void => window.location.reload();

  const handleThemeChange = () => {
    setDark((state) => !state);
  };

  const appTree = (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />

      <ModalContext.Provider value={modalStore}>
        <ConfirmationModal isAddin />
        <AddinContainer>
          <NotificationsProvider store={notificationStore}>
            <QueryClientProvider>
              {apiServicesInitialized ? (
                <UserAnalyticsProvider
                  handleAmplitudeIdentify={handleAmplitudeIdentify.bind(ampli)}
                  setAmplitudeGroup={setAmplitudeGroup.bind(ampli)}
                  webComponentMoniker={InventorWebComponentMoniker}
                >
                  {enableMaintenanceMode || inventorWebComponentUnderMaintenance ? (
                    <UnderMaintenance />
                  ) : (
                    <NavigationContext.Provider value={navigationStore}>
                      <TabProgressContext.Provider value={tabProgressStore}>
                        {enableDarkModeSwitch && (
                          <Switch
                            onChange={handleThemeChange}
                            sx={{ position: 'absolute', top: 0, left: '50%', zIndex: 1000 }}
                          />
                        )}
                        <Header />
                        <EnvironmentInfo hostname={window.location.host} />
                        <ScreenManager />
                      </TabProgressContext.Provider>
                    </NavigationContext.Provider>
                  )}
                </UserAnalyticsProvider>
              ) : (
                <AppLoadingProgress />
              )}
            </QueryClientProvider>
          </NotificationsProvider>
        </AddinContainer>
      </ModalContext.Provider>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
