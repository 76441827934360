export const CONTROL_BLOCK_EXTENSION = 'CONTROL_BLOCK_EXTENSION';
export const CONTROL_BLOCK_MUTATOR = 'CONTROL_BLOCK_MUTATOR';
export const FORM_CONTAINER_BLOCK_MUTATOR = 'FORM_CONTAINER_BLOCK_MUTATOR';

// FormCodeblocks Input and Field constants
export const blocklyLabel = 'label';
export const blocklyStatementInput = 'statement_input';
export const blocklyFormName = 'form_name';
export const controlBlock = 'control_block';
export const formContainerBlock = 'form_container_block';
export const groupBlock = 'group_block';
export const groupBlockName = 'group_block_name';
export const groupBlockOpenByDefaultField = 'open_by_default';
export const groupBlockInnerBlocksField = 'inner_blocks';

export const controlBlockColor = '#A55B7F';
export const formContainerBlockColor = '#A57A5B';
export const groupBlockColor = '#A57A5B';

export const formRulesKey = 'formRules';

export const workspaceHelpLink = 'https://developers.google.com/blockly/guides/get-started/workspace-anatomy';
