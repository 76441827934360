import { Typography, Chip } from '@mui/material';
import { FlexContainer, RightSection } from '@mid-react-common/common';
import { GroupedOutputsProps } from '../Output.types';
import { OutputGrid } from '../OutputsTab.styles';
import { GroupedOutputRepresentations } from './GroupedOutputRepresentations';

export const GroupedOutputs: React.FC<GroupedOutputsProps> = ({ title, outputs }): JSX.Element => (
  <>
    <FlexContainer>
      <Typography variant="h2">{title}</Typography>
      <RightSection>
        {outputs.map((outputType) => {
          const selectedRepresentationForCurrentOutput = outputType.selectedRepresentations.length;
          return selectedRepresentationForCurrentOutput
            ? outputType.acronyms.map((acronym) => <Chip key={`${outputType.outputType}-${acronym}`} label={acronym} />)
            : null;
        })}
      </RightSection>
    </FlexContainer>
    <OutputGrid>
      <GroupedOutputRepresentations outputs={outputs} />
    </OutputGrid>
  </>
);
