import React, { useCallback, useContext } from 'react';
import Blockly from 'blockly';
import { importCodeBlocks, exportCodeBlocks, ImportCodeBlocksStatus, ExportCodeBlocksStatus } from 'mid-addin-lib';
import text from 'inventor.text.json';
import { adoptBlocks } from './transferBlocks.utils';
import { logError } from 'mid-utils';
import { NotificationContext } from '@mid-react-common/common';
import { initializeExportPlugin } from '../../Plugins/ExportPlugin';
import { initializeImportPlugin } from '../../Plugins/ImportPlugin';
import { BlocklyWorkspace } from 'components/Rules/types';

interface useTransferBlocksProps {
  blocklyRef: React.MutableRefObject<Blockly.WorkspaceSvg | undefined>;
  productDefinitionName: string;
  productDefinitionTopLevelFolder: string;
}

interface useTransferBlocksReturn {
  initialize: (workspace: Blockly.WorkspaceSvg) => void;
}

const loadWorkspaceWithoutCopyingExistingBlocksToTrash = (
  blocklyWorkspace: Blockly.WorkspaceSvg,
  newState: BlocklyWorkspace,
): void => {
  // Fix for: https://jira.autodesk.com/browse/TRADES-5912

  // 1. We clear the workspace before .load is called as
  // it loads all existing blocks into the trash (soft-delete) &
  // we do not want this behaviour, as the existing state is preserved
  // when we import new blocks

  // 2. When you run workspace.clear() it disposes of all blocks in the workspace,
  // which in turn fires DELETE events for each disposed block. A workspace change
  // listener adds those deleted blocks to the trashcan. One way you can get around
  // this is to temporarily disable events and then clear the workspace
  // https://groups.google.com/g/blockly/c/m7e3g0TC75Y
  Blockly.Events.disable();
  blocklyWorkspace.clear();
  Blockly.Events.enable();

  Blockly.serialization.workspaces.load(newState, blocklyWorkspace);
};

const useTransferBlocks = ({
  blocklyRef,
  productDefinitionName,
  productDefinitionTopLevelFolder,
}: useTransferBlocksProps): useTransferBlocksReturn => {
  const { showNotification } = useContext(NotificationContext);
  const handleImportBlocks = useCallback(
    async (blocklyWorkspace: Blockly.WorkspaceSvg) => {
      if (!blocklyRef.current) {
        return;
      }

      const importResult = await importCodeBlocks({
        fileLocation: productDefinitionTopLevelFolder,
        skipDialog: false,
      });

      if (importResult.status === ImportCodeBlocksStatus.success && importResult.codeBlocks) {
        try {
          const blocks = JSON.parse(importResult.codeBlocks);
          const newState = adoptBlocks(blocks, blocklyRef.current);
          loadWorkspaceWithoutCopyingExistingBlocksToTrash(blocklyWorkspace, newState);

          showNotification({
            message: text.importBlocksSuccessMessage,
            severity: 'success',
          });
        } catch (ex) {
          showNotification({ message: text.importBlocksFileParsingError, severity: 'error' });
          logError(ex);
        }
      }
    },
    [blocklyRef, productDefinitionTopLevelFolder, showNotification],
  );

  const handleExportBlocks = useCallback(
    async (serializedWorkspace: string) => {
      try {
        const exportResult = await exportCodeBlocks(serializedWorkspace, {
          fileName: `${productDefinitionName}-canvas.idc`,
          fileLocation: productDefinitionTopLevelFolder,
          skipDialog: false,
        });

        if (exportResult.status === ExportCodeBlocksStatus.success) {
          showNotification({
            message: text.exportBlocksSuccessMessage,
            severity: 'success',
          });
        }

        if (exportResult.status === ExportCodeBlocksStatus.cancel) {
          showNotification({
            message: text.exportBlocksCancelledMessage,
            severity: 'info',
          });
        }

        if (exportResult.message) {
          logError(new Error(exportResult.message));
          showNotification({
            message: text.exportBlocksErrorMessage,
            severity: 'error',
          });
        }
      } catch (ex) {
        logError(ex);
      }
    },
    [productDefinitionName, productDefinitionTopLevelFolder, showNotification],
  );

  const initialize = (workspace: Blockly.WorkspaceSvg) => {
    initializeExportPlugin(workspace, handleExportBlocks);
    initializeImportPlugin(workspace, handleImportBlocks);
  };

  return {
    initialize,
  };
};

export default useTransferBlocks;
