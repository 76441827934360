import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const ContentWrapper = styled('div')`
  padding: ${({ theme }) => theme.var.paddingBase}px;
  min-width: 300px;
`;
export const FolderTitle = styled('span')`
  color: ${({ theme }) => theme.palette.grey[300]};
  margin-bottom: ${({ theme }) => theme.var.marginBase}px;
`;

export const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.var.marginBase}px;
`;
