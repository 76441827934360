import { ProductDefinitionOutput } from 'mid-addin-lib';
import { OutputType } from '@adsk/offsite-dc-sdk';
import { DropdownItem } from '@mid-react-common/addins';
import { logError } from 'mid-utils';
import { useCallback, useEffect, useState } from 'react';
import { revitFamilyCategories } from '../../constants';
import { debounce } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  productDefinitionActions,
  useProductDefinitionStore,
} from '../../../../../context/DataStore/productDefinitionStore';
import { useShallow } from 'zustand/react/shallow';

interface UseLegacyRevitCategoryAndFamilySelectorState {
  revitFamilyCategory: DropdownItem | null;
  localRevitFamily: string;
  revitFamilyCategoryList: DropdownItem[];
  handleOnSelectCategory: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleRevitFamilyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useLegacyRevitCategoryAndFamilySelector = (): UseLegacyRevitCategoryAndFamilySelectorState => {
  const { enforceRevitClassification } = useFlags();
  const { outputs, productDefinitionName } = useProductDefinitionStore(
    useShallow((state) => ({ outputs: state.outputs, productDefinitionName: state.name })),
  );

  const currentProductDefinitionRevitFamilyOutput: ProductDefinitionOutput | undefined = outputs.find(
    (output) => output.type === OutputType.RFA,
  );

  // All dropdown items used by Category dropdown on Output tab - Revit Family Output
  const revitFamilyCategoryList: DropdownItem[] = revitFamilyCategories.map((category) => ({
    label: category,
    value: category,
  }));

  const revitFamilyCategory = currentProductDefinitionRevitFamilyOutput?.options?.category;

  const revitFamilyCategoryDropdownItem: DropdownItem | null = revitFamilyCategory
    ? {
        label: revitFamilyCategory,
        value: revitFamilyCategory,
      }
    : null;

  const [localRevitFamily, setLocalRevitFamily] = useState(
    currentProductDefinitionRevitFamilyOutput?.options?.family || productDefinitionName,
  );

  const debounceUpdateRevitFamilyOutputOptions = debounce(
    (revitFamilyOutputOptions: ProductDefinitionOutput['options']) =>
      productDefinitionActions.addOutputOptions(OutputType.RFA, revitFamilyOutputOptions),
    500,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRFAOutputDebounced = useCallback(
    debounceUpdateRevitFamilyOutputOptions,
    [], // Creating it only once initially.
  );

  useEffect(() => {
    if (localRevitFamily !== currentProductDefinitionRevitFamilyOutput?.options?.family) {
      const updatedOptions: ProductDefinitionOutput['options'] = {
        family: localRevitFamily,
      };
      if (!enforceRevitClassification) {
        onRFAOutputDebounced(updatedOptions);
      }
    }
  }, [
    currentProductDefinitionRevitFamilyOutput?.options?.family,
    enforceRevitClassification,
    localRevitFamily,
    onRFAOutputDebounced,
  ]);

  const handleOnSelectCategory = (changes: { selectedItem?: DropdownItem | null }) => {
    if (!changes.selectedItem) {
      logError('Error: User must select a revit category from the dropdown.');
    } else {
      const updatedOptions: ProductDefinitionOutput['options'] = {
        category: changes.selectedItem.value.toString(),
      };
      if (!enforceRevitClassification) {
        productDefinitionActions.addOutputOptions(OutputType.RFA, updatedOptions);
      }
    }
  };

  const handleRevitFamilyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalRevitFamily(event.target.value);
  };

  return {
    revitFamilyCategory: revitFamilyCategoryDropdownItem,
    localRevitFamily,
    revitFamilyCategoryList,
    handleOnSelectCategory,
    handleRevitFamilyChange,
  };
};
