import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { FlexContainer, RightSection } from '@mid-react-common/common';
import React from 'react';
import { OutputProps } from './Output.types';
import OutputRepresentations from './OutputRepresentations';
import { OutputGrid } from './OutputsTab.styles';

const Output: React.FC<OutputProps> = ({
  outputType,
  title,
  acronyms,
  children,
  disabled,
  representations,
  selectedRepresentations,
  handleRepresentationChange,
  showExcludeIntellectualProperty,
  tooltipMessage,
  isRequired,
}): JSX.Element => (
  <>
    <FlexContainer>
      <Typography variant="h2">{title}</Typography>
      <RightSection>
        {acronyms.map((acronym) => (
          <Chip key={acronym} label={acronym} />
        ))}
      </RightSection>
    </FlexContainer>
    <OutputGrid>
      {children}
      <OutputRepresentations
        outputType={outputType}
        disabled={disabled}
        representations={representations}
        selectedRepresentations={selectedRepresentations || []}
        handleRepresentationChange={handleRepresentationChange}
        showExcludeIntellectualProperty={showExcludeIntellectualProperty}
        tooltipMessage={tooltipMessage}
        isRequired={isRequired}
      />
    </OutputGrid>
  </>
);

export default Output;
