import { useTheme } from '@mui/material/styles';
import testIds from 'inventor.testids';
import text from 'inventor.text.json';
import { FlexContainer, TextFieldWrapper } from '@mid-react-common/common';
import React from 'react';
import { RevitClassification } from 'mid-addin-lib';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { bimDefinitionLearnMoreLink } from './constants';

interface RevitClassificationSectionProps {
  isLoading: boolean;
  revitClassification: RevitClassification;
}

const TooltipContent: React.FC = () => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={`${theme.var.gapBase}px`} margin={`${theme.var.marginBase / 2}px`}>
      {text.revitClassificationTooltip}
      <Divider />
      <Link underline="none" href={bimDefinitionLearnMoreLink} target="_blank" className="mid-tooltip-link">
        {text.revitClassificationTooltipLearnMore}
      </Link>
    </Box>
  );
};

const RevitClassificationSection: React.FC<RevitClassificationSectionProps> = ({ isLoading, revitClassification }) => {
  const theme = useTheme();

  return (
    <FlexContainer flexDirection="column" gap={theme.var.paddingBase * 2}>
      <TextFieldWrapper
        data-testid={testIds.revitFamilyCategory}
        disabled
        size="small"
        label={text.revitFamilyCategory}
        type="text"
        value={revitClassification.category || ''}
        required
        width={theme.var.outputTabInputWidth}
        InputProps={{
          endAdornment: !isLoading && (
            <InputAdornment position="end">
              {revitClassification.category ? (
                <Tooltip
                  data-testid={testIds.revitClassificationTooltipLocked}
                  title={<TooltipContent />}
                  placement="bottom"
                  arrow
                >
                  <IconButton>
                    <LockIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  data-testid={testIds.revitClassificationTooltipError}
                  title={<TooltipContent />}
                  placement="bottom"
                  arrow
                >
                  <IconButton>
                    <ErrorIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />

      <TextFieldWrapper
        data-testid={testIds.revitFamilyName}
        disabled
        size="small"
        label={text.revitFamilyLabel}
        type="text"
        value={revitClassification.familyName}
        required
        width={theme.var.outputTabInputWidth}
        InputProps={{
          endAdornment: !isLoading && (
            <InputAdornment position="end">
              {revitClassification.familyName ? (
                <Tooltip
                  data-testid={testIds.revitClassificationTooltipLocked}
                  title={<TooltipContent />}
                  placement="bottom"
                  arrow
                >
                  <IconButton>
                    <LockIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  data-testid={testIds.revitClassificationTooltipError}
                  title={<TooltipContent />}
                  placement="bottom"
                  arrow
                >
                  <IconButton>
                    <ErrorIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
    </FlexContainer>
  );
};

export default RevitClassificationSection;
