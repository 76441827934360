import { FormControl } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { useTheme } from '@mui/material/styles';
import text from 'inventor.text.json';
import { FlexContainer, RightButton, TextFieldWrapper } from '@mid-react-common/common';
import React from 'react';
import { sourceContentTestIds } from '../../../../tests/helpers/dataTestIds';

interface ProjectSectionProps {
  iPJ: string;
  handleSelectInventorProjectClick: () => Promise<void>;
}

const ProjectSection: React.FC<ProjectSectionProps> = ({ iPJ, handleSelectInventorProjectClick }): JSX.Element => {
  const theme = useTheme();
  return (
    <FormControl required>
      <FormLabel>{text.sourceContentIPJLabel}</FormLabel>
      <FlexContainer>
        <TextFieldWrapper
          width={theme.var.wideInputControl}
          size="small"
          inputProps={{ readOnly: true, 'data-testid': sourceContentTestIds.ipjInput }}
          type="text"
          placeholder={text.NotSelectedLabel}
          value={iPJ}
        />
        {iPJ ? (
          <RightButton
            data-testid={sourceContentTestIds.ipjReplaceButton}
            onClick={handleSelectInventorProjectClick}
            variant="outlined"
            size="medium"
          >
            {text.buttonReplace}
          </RightButton>
        ) : (
          <RightButton
            data-testid={sourceContentTestIds.ipjSelectButton}
            onClick={handleSelectInventorProjectClick}
            variant="contained"
            size="medium"
          >
            {text.buttonSelect}
          </RightButton>
        )}
      </FlexContainer>
    </FormControl>
  );
};

export default ProjectSection;
