import Divider from '@mui/material/Divider';
import { debounce } from 'lodash';
import { FlexContainer, useTableLoadingState } from '@mid-react-common/common';
import React, { useEffect, useState } from 'react';
import text from '../../../../inventor.text.json';
import InputsHeader from './InputsHeader';
import {
  AdoptedInputsContainer,
  AdoptedInputsTooltip,
  AdoptedInputsTooltipContent,
  AdoptedInputsTooltipTitle,
  InputsSelectionContainer,
  InputsSelectionTable,
  TableContainer,
} from './InputsSelection.styles';
import { InputsSidebar } from './InputsSidebar/InputsSidebar';
import { SidebarLabel } from './InputsSidebar/InputsSidebar.styles';
import { adoptedTooltipLink, getParameterTableColumns } from './constants';
import { useInputsHeader } from './useInputsHeader';
import useInputsSelection from './useInputsSelection';
import { useInputsTable } from './useInputsTable';
import { useTransformToTableIds } from './useTransformToTableIds';
import InfoIcon from '@mui/icons-material/Info';
import Link from '@mui/material/Link';

const InputsSelection: React.FC = (): JSX.Element => {
  const { inventorData, productDefinitionInputs, selectedParameters, setSelectedParameters, loading } = useInputsSelection();

  const { buttonGroupValue, currentFilter, setCurrentFilter, filterItems } = useInputsHeader();

  const [parameterColumns, setParameterColumns] = useState(getParameterTableColumns());

  useEffect(() => {
    const windowResizeCallback = () => {
      setParameterColumns(getParameterTableColumns());
    };
    const debouncedWindowResizeCallback = debounce(windowResizeCallback, 300);

    window.addEventListener('resize', debouncedWindowResizeCallback);

    return () => window.removeEventListener('resize', debouncedWindowResizeCallback);
  });

  const selectedIds = useTransformToTableIds(selectedParameters);

  const totalSelected = selectedParameters.length;

  const { tableData, onInputSelection, filteredData, renderEmptyState } = useInputsTable({
    initialTableData: inventorData ? inventorData.parameters : null,
    currentFilter,
    setSelectedInputIds: setSelectedParameters,
  });

  return (
    <InputsSelectionContainer>
      <TableContainer>
        <InputsHeader
          buttonGroupValue={buttonGroupValue}
          filterItems={filterItems}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          totalSelected={totalSelected}
        />

        <InputsSelectionTable
          checkboxSelection
          density="compact"
          disableColumnFilter
          hideFooterPagination
          initialState={{ columns: { columnVisibilityModel: { isKey: false } } }}
          columns={parameterColumns}
          rows={tableData}
          getRowId={(row) => row['name']}
          selectionModel={selectedIds}
          onSelectionModelChange={onInputSelection}
          filterModel={filteredData}
          loading={loading}
          components={{
            NoRowsOverlay: renderEmptyState,
            LoadingOverlay: useTableLoadingState,
          }}
        />
      </TableContainer>
      <Divider orientation="vertical" flexItem />
      <AdoptedInputsContainer className="mid-bg-shadow">
        <FlexContainer alignItems="center">
          <SidebarLabel variant="h2">{`${text.inputsAdopted} (${productDefinitionInputs.length})`} </SidebarLabel>
          {productDefinitionInputs.length > 0 && (
            <AdoptedInputsTooltip
              title={
                <>
                  <AdoptedInputsTooltipTitle variant="body1">{text.inputsAdoptedTooltipTitle}</AdoptedInputsTooltipTitle>
                  <AdoptedInputsTooltipContent>{text.inputsAdoptedTooltipContent}</AdoptedInputsTooltipContent>
                  <Link
                    className="mid-tooltip-link"
                    underline="none"
                    href={adoptedTooltipLink}
                    variant="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text.inputsAdoptedLinkText}
                  </Link>
                </>
              }
              arrow
            >
              <InfoIcon fontSize="small" />
            </AdoptedInputsTooltip>
          )}
        </FlexContainer>
        <InputsSidebar selectedInputs={productDefinitionInputs} />
      </AdoptedInputsContainer>
    </InputsSelectionContainer>
  );
};

export default InputsSelection;
