import Typography from '@mui/material/Typography';
import text from 'inventor.text.json';
import { ProductDefinition } from 'mid-addin-lib';
import { SummaryTable, SummaryTableRow } from '@mid-react-common/common';
import React from 'react';
import { ProductDefinitionSummary, PublishTableContainer } from './Publishing.styles';
import { publishTestIds } from 'tests/helpers/dataTestIds';
import {
  getProductDefinitionSummaryData,
  getReleaseInfoSummaryData,
  getProductOutputsSummaryData,
  OutputsSummaryTableData,
  getRFASummaryData,
} from './publishing.utils';
import Divider from '@mui/material/Divider';
import OutputsSummaryTable from './OutputsSummaryTable/OutputsSummaryTable';

export interface PublishingCompleteProps {
  currentProductDefinition: ProductDefinition;
  publishedProductName: string;
  release: number | undefined;
}

export const PublishingComplete: React.FC<PublishingCompleteProps> = ({
  currentProductDefinition,
  publishedProductName,
  release,
}): JSX.Element => {
  const releaseInfoData: SummaryTableRow[] = getReleaseInfoSummaryData({
    publishedProductName,
    currentProductDefinition,
    release,
    notes: currentProductDefinition.notes,
  });
  const productDefinitionSummaryData: SummaryTableRow[] = getProductDefinitionSummaryData(currentProductDefinition);
  const rfaSummaryData: SummaryTableRow[] = getRFASummaryData(currentProductDefinition);
  const productOutputsSummaryData: OutputsSummaryTableData[] = getProductOutputsSummaryData(currentProductDefinition);
  return (
    <>
      <ProductDefinitionSummary data-testid={publishTestIds.publishReleaseSummarySection}>
        <Typography variant="h2" fontWeight="bolder" gutterBottom>
          {text.publishRelease}
        </Typography>
        <PublishTableContainer className="mid-border-shadow">
          <SummaryTable data={releaseInfoData} removeBorder removeBackground />
        </PublishTableContainer>
      </ProductDefinitionSummary>
      <ProductDefinitionSummary data-testid={publishTestIds.productDefinitionSummarySection}>
        <Typography variant="h2" fontWeight="bolder" gutterBottom>
          {text.publishSummaryTableTitle}
        </Typography>
        <PublishTableContainer className="mid-border-shadow">
          <SummaryTable data={productDefinitionSummaryData} removeBorder removeBackground />
          <Divider />
          <SummaryTable data={rfaSummaryData} removeBorder removeBackground />
        </PublishTableContainer>
      </ProductDefinitionSummary>
      <ProductDefinitionSummary data-testid={publishTestIds.publishOutputsSummarySection}>
        <PublishTableContainer className="mid-border-shadow">
          <OutputsSummaryTable data={productOutputsSummaryData} />
        </PublishTableContainer>
      </ProductDefinitionSummary>
    </>
  );
};
