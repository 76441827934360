import {
  FileFilter,
  getActiveDocumentInfo,
  selectFile,
  getPathSeparator,
  ProductDefinitionOutput,
  createFullPath,
} from 'mid-addin-lib';
import { useLogAndShowNotification } from '@mid-react-common/common';
import { useEffect, useMemo, useCallback } from 'react';
import { pathSeparators } from 'mid-utils';
import { DrawingThumbnail } from 'mid-types';
import { productDefinitionActions, useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { inventorStoreActions } from '../../../context/DataStore/InventorDataStore';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

export interface TopLevelFolderOptions {
  folder: string;
  path: string;
}

interface UseSourceContentState {
  initialName: string;
  topLevelFolder: string;
  inventorProjectPath: string;
  assemblyPath: string;
  handleSelectInventorProjectClick: () => Promise<void>;
  topLevelFolderOptions: TopLevelFolderOptions[];
  handleTopLevelFolderChange: (newTopLevelFolder: string) => void;
}

const INVENTOR_DOCUMENT_INFO_KEY = 'inventorDocumentInfo';

export const useSourceContent = (): UseSourceContentState => {
  const { productDefinitionName, topLevelFolder, assembly, inventorProject, outputs, drawingThumbnails } =
    useProductDefinitionStore(
      useShallow((state) => ({
        productDefinitionName: state.name,
        topLevelFolder: state.topLevelFolder,
        assembly: state.assembly,
        inventorProject: state.inventorProject,
        outputs: state.outputs,
        drawingThumbnails: state.drawingThumbnails,
      })),
    );

  /*
   * Assembly
   */
  const { data: inventorDocumentInfo, error: inventorDocumentInfoError } = useQuery({
    queryKey: [INVENTOR_DOCUMENT_INFO_KEY],
    queryFn: async () => {
      const documentInfo = await getActiveDocumentInfo();
      if (!productDefinitionName) {
        productDefinitionActions.setName(documentInfo.name.replace('.ipt', '').replace('.iam', ''));
      }

      return documentInfo;
    },
  });

  useLogAndShowNotification(inventorDocumentInfoError, inventorDocumentInfoError?.message || '');

  useEffect(() => {
    if (inventorDocumentInfo && !assembly) {
      productDefinitionActions.setSourceModel({
        assembly: createFullPath(inventorDocumentInfo.location, inventorDocumentInfo.name),
      });
    }
  }, [assembly, inventorDocumentInfo]);

  /*
   * Initialize Product Definition Name
   */

  const initialName = useMemo(
    () => (inventorDocumentInfo?.name ? inventorDocumentInfo.name.replace('.ipt', '').replace('.iam', '') : ''),
    [inventorDocumentInfo?.name],
  );

  // If file is part of top level folder, return a relative path,
  // Otherwise return an absolute path
  const getPathRelativeToTopLevelFolder = (fullPath: string, topLevelFolder: string): string =>
    fullPath.replace(topLevelFolder, '');

  const initialTopLevelFolder = inventorDocumentInfo?.location;

  /*
   * IPJ
   */
  const handleSelectInventorProjectClick = useCallback(async () => {
    if (!initialTopLevelFolder && !topLevelFolder) {
      return;
    }

    const ipjFilter: FileFilter[] = [{ name: 'Project Files(*.ipj)', expression: '*.ipj' }];
    const [inventorProject] = await selectFile(ipjFilter, false, topLevelFolder || initialTopLevelFolder);
    if (inventorProject) {
      const updatedInventorProject = topLevelFolder
        ? getPathRelativeToTopLevelFolder(inventorProject, topLevelFolder)
        : inventorProject;

      productDefinitionActions.setSourceModel({
        inventorProject: updatedInventorProject,
      });
    }
  }, [initialTopLevelFolder, topLevelFolder]);

  /**
   * Dropdown options for different top level folders
   */
  const topLevelFolderOptions: TopLevelFolderOptions[] = useMemo(() => {
    if (!initialTopLevelFolder) {
      return [];
    }

    const pathSeperator = getPathSeparator(initialTopLevelFolder);
    const folders = initialTopLevelFolder.split(pathSeperator);
    // Create a tree-ish options in Dropdown
    const FIRST_FOLDER_INDEX = 0;
    const folderOptions: TopLevelFolderOptions[] = folders.map((folder, index) => ({
      folder,
      path: folders.slice(FIRST_FOLDER_INDEX, index + 1).join(pathSeperator),
    }));

    // Remove first element since it is pointed to root of Drive (C:\ or D:\)
    return folderOptions.slice(1);
  }, [initialTopLevelFolder]);

  /**
   * Beginning of logic for handling top level change
   */
  const handleTopLevelFolderChangeForDrawingOutputs = useCallback(
    (newTopLevelFolder: string) => {
      const existingDrawingOutputs = outputs.filter((output) => !!output.options?.drawingTemplatePath);
      const allRelativePathsOfDrawings = existingDrawingOutputs.reduce<string[]>(
        (drawingPaths, drawing) =>
          drawing.options?.drawingTemplatePath ? [...drawingPaths, drawing.options.drawingTemplatePath] : drawingPaths,
        [],
      );
      // Delete existing drawing outputs and re-insert all drawing outputs with updated paths
      productDefinitionActions.deleteDrawingOutputs(allRelativePathsOfDrawings);

      const drawingOutputsWithUpdatedPaths = existingDrawingOutputs.reduce<ProductDefinitionOutput[]>(
        (updatedOutputs, output) => {
          const relativePath = output.options?.drawingTemplatePath;

          if (!relativePath) {
            return updatedOutputs;
          }

          // Convert existing relative path to an absolute path and test to see
          // if new top level folder is part of absolute path
          const absolutePathOfExistingDrawing = createFullPath(topLevelFolder, relativePath);
          if (absolutePathOfExistingDrawing.startsWith(newTopLevelFolder)) {
            return [
              ...updatedOutputs,
              {
                options: {
                  drawingTemplatePath: getPathRelativeToTopLevelFolder(absolutePathOfExistingDrawing, newTopLevelFolder),
                },
                type: output.type,
              },
            ];
          }

          return updatedOutputs;
        },
        [],
      );

      productDefinitionActions.addOutputs(drawingOutputsWithUpdatedPaths);
    },
    [topLevelFolder, outputs],
  );

  const handleTopLevelFolderChangeForDrawingThumbnails = useCallback(
    (newTopLevelFolder: string) => {
      if (drawingThumbnails) {
        const drawingThumbnailsWithUpdatedPaths = drawingThumbnails.reduce<DrawingThumbnail[]>(
          (updatedDrawingThumbnails, drawing) => {
            const absolutePath = createFullPath(topLevelFolder, drawing.relativePath);

            if (absolutePath.startsWith(newTopLevelFolder)) {
              return [
                ...updatedDrawingThumbnails,
                { ...drawing, relativePath: getPathRelativeToTopLevelFolder(absolutePath, newTopLevelFolder) },
              ];
            }

            return updatedDrawingThumbnails;
          },
          [],
        );
        productDefinitionActions.setDrawingsThumbnails(drawingThumbnailsWithUpdatedPaths);
      }
    },
    [drawingThumbnails, topLevelFolder],
  );

  const handleTopLevelFolderChangeForAssemblyAndIpj = useCallback(
    (newTopLevelFolder: string) => {
      const assemblyAbsolutePath = topLevelFolder ? createFullPath(topLevelFolder, assembly) : assembly;
      // Inventor project can be either absolute path, relative path or empty path
      const inventorProjectAbsolutePath =
        topLevelFolder && inventorProject.startsWith(pathSeparators.BACKSLASH)
          ? createFullPath(topLevelFolder, inventorProject)
          : inventorProject;
      productDefinitionActions.setSourceModel({
        assembly: getPathRelativeToTopLevelFolder(assemblyAbsolutePath, newTopLevelFolder),
        inventorProject: getPathRelativeToTopLevelFolder(inventorProjectAbsolutePath, newTopLevelFolder),
        topLevelFolder: newTopLevelFolder,
      });
    },
    [topLevelFolder, assembly, inventorProject],
  );

  const handleTopLevelFolderChange = useCallback(
    (newTopLevelFolder: string): void => {
      if (!initialTopLevelFolder || newTopLevelFolder === topLevelFolder) {
        return;
      }

      // If top level folder has not been set, then drawings and drawing outputs have not been set previously
      if (topLevelFolder) {
        handleTopLevelFolderChangeForDrawingOutputs(newTopLevelFolder);
        handleTopLevelFolderChangeForDrawingThumbnails(newTopLevelFolder);
      }

      handleTopLevelFolderChangeForAssemblyAndIpj(newTopLevelFolder);
      // Trigger to rescan the folder to see if there are new drawings
      inventorStoreActions.setAreDrawingFilesFetched(false);
    },
    [
      initialTopLevelFolder,
      topLevelFolder,
      handleTopLevelFolderChangeForDrawingThumbnails,
      handleTopLevelFolderChangeForDrawingOutputs,
      handleTopLevelFolderChangeForAssemblyAndIpj,
    ],
  );
  /**
   * End of logic for handling top level change
   */

  return {
    initialName,
    topLevelFolder,
    inventorProjectPath: inventorProject,
    assemblyPath: assembly,
    handleSelectInventorProjectClick,
    topLevelFolderOptions,
    handleTopLevelFolderChange,
  };
};

export default useSourceContent;
