import { RightSection } from '@mid-react-common/common';
import React from 'react';
import InputsFilter from './InputsFilter';
import { InputsHeaderWrapper } from './InputsSelection.styles';
import { InputHeaderCurrentFilter } from './useInputsHeader';

export interface InputsHeaderProps {
  buttonGroupValue: string;
  currentFilter: InputHeaderCurrentFilter;
  setCurrentFilter: React.Dispatch<React.SetStateAction<InputHeaderCurrentFilter>>;
  filterItems: string[];
  totalSelected: number;
}

const InputsHeader: React.FC<InputsHeaderProps> = ({ currentFilter, setCurrentFilter, filterItems }): JSX.Element => (
  <InputsHeaderWrapper>
    <RightSection>
      <InputsFilter items={filterItems} inputHeaderCurrentFilter={currentFilter} setFilter={setCurrentFilter} />
    </RightSection>
  </InputsHeaderWrapper>
);

export default InputsHeader;
