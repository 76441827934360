import Box from '@mui/material/Box';
import text from 'inventor.text.json';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AccordionWrapper } from '../Publishing.styles';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { publishingInvalidtestIds } from 'tests/helpers/dataTestIds';
const publishValidationText = text.publishValidation;

interface ValidationSectionProps {
  title: string;
  description: string;
  isComplete: boolean;
  resources?: {
    label: string;
    url: string;
  }[];
  resolutionSteps?: string[];
}

const ValidationSection: React.FC<ValidationSectionProps> = ({
  description,
  isComplete,
  title,
  resources,
  resolutionSteps,
}): JSX.Element => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(!isComplete);
  const handleToggleExpanded = () => {
    setExpanded((currenState) => !currenState);
  };

  useEffect(() => {
    setExpanded(!isComplete);
  }, [isComplete]);

  return (
    <AccordionWrapper
      disableGutters
      defaultExpanded={expanded}
      expanded={expanded}
      onChange={handleToggleExpanded}
      data-testid={
        isComplete
          ? `${publishingInvalidtestIds.sectionValid}-${title}`
          : `${publishingInvalidtestIds.sectionInvalid}-${title}`
      }
    >
      <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
        <Box display="flex" alignItems="center" gap={`${theme.var.gapBase}px`}>
          {isComplete ? (
            <CheckCircleIcon color="primary" data-testid={`${publishingInvalidtestIds.sectionValidIcon}`} />
          ) : (
            <ErrorOutlineIcon color="error" data-testid={`${publishingInvalidtestIds.sectionInvalidIcon}`} />
          )}
          <Typography fontWeight="bolder">{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography gutterBottom>{description}</Typography>
        {isComplete ? (
          <Typography fontWeight="bolder">{publishValidationText.requirementComplete}</Typography>
        ) : (
          <>
            <Typography display="inline" fontWeight="bolder">
              {publishValidationText.requirementIncomplete}{' '}
            </Typography>
            {resources?.length && (
              <>
                <Typography display="inline">{publishValidationText.referToResources}</Typography>
                <ul>
                  {resources.map((resource) => (
                    <li key={resource.url}>
                      <Link underline="none" target="_blank" href={resource.url}>
                        {resource.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {resolutionSteps?.length && (
              <>
                <Typography display="inline">{publishValidationText.toResolve}</Typography>
                <ol>
                  {resolutionSteps.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ol>
              </>
            )}
          </>
        )}
      </AccordionDetails>
    </AccordionWrapper>
  );
};

export default ValidationSection;
