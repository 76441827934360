import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyInputParameterDropdownValues } from '../types';
import { BlockSvg } from 'blockly';
import {
  codeRunnerGetInput,
  codeRunnerSetOutput,
  HIGHLIGHT_CODE_BLOCK,
  loopTrapVariableName,
  parametersVariableName,
} from '@adsk/informed-design-code-runner';
import {
  blocklyCommentBlockName,
  blocklyCommentBlockPlaceholder,
  blocklyConnectingBlock,
  blocklyFunctionsDropdown,
  blocklyParameterIcon,
  commentBlock,
  customBlocklyExtensions,
  customBlocklyMutators,
  universalInputBlock,
  universalOutputBlock,
} from './InputCodeblocks.constants';
import { blocklyInputsDropdown, INFINITE_LOOP_TRAP_CODE } from '../constants';

const inputWorkspaceCustomBlocks = [
  {
    type: universalOutputBlock,
    message0: '%1 %2 . %3',
    args0: [
      {
        type: 'input_dummy',
        name: blocklyParameterIcon,
      },
      {
        type: 'input_dummy',
        name: blocklyInputsDropdown,
      },
      {
        type: 'input_dummy',
        name: blocklyFunctionsDropdown,
      },
    ],
    inputsInline: true,
    output: null,
    colour: 230,
    helpUrl: '',
    extensions: [customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION],
    mutator: customBlocklyMutators.INPUT_DROPDOWN_MUTATOR,
  },
  {
    type: universalInputBlock,
    message0: '%1 %2 . %3 = %4',
    args0: [
      {
        type: 'input_dummy',
        name: blocklyParameterIcon,
      },
      {
        type: 'input_dummy',
        name: blocklyInputsDropdown,
      },
      {
        type: 'input_dummy',
        name: blocklyFunctionsDropdown,
      },
      {
        type: 'input_value',
        name: blocklyConnectingBlock,
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: 230,
    tooltip: 'tooltip',
    helpUrl: '',
    extensions: [customBlocklyExtensions.INPUT_DROPDOWN_EXTENSION],
    mutator: customBlocklyMutators.INPUT_DROPDOWN_MUTATOR,
  },
  {
    type: commentBlock,
    message0: '// %1',
    args0: [
      {
        type: 'field_input',
        name: blocklyCommentBlockName,
        text: blocklyCommentBlockPlaceholder,
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 180,
    tooltip: '',
    helpUrl: '',
  },
];
export { inputWorkspaceCustomBlocks };

/*
 * This file is used to tell Blockly how the custom blocks generate code.
 */

javascriptGenerator[universalInputBlock] = function (this: BlockSvg) {
  const dropdownParameter = this.getField(blocklyInputsDropdown)?.getValue();
  const dropdownAction = this.getField(blocklyFunctionsDropdown)?.getValue();

  const valueConnectingBlock = javascriptGenerator.valueToCode(
    this,
    blocklyConnectingBlock,
    javascriptGenerator.ORDER_ATOMIC,
  );

  const dropdownValue: BlocklyInputParameterDropdownValues = JSON.parse(dropdownParameter);
  return `${parametersVariableName}['${dropdownValue.name}'].${dropdownAction} = ${valueConnectingBlock};\n`;
};

javascriptGenerator[universalOutputBlock] = function (this: BlockSvg) {
  const dropdownParameter = this.getField(blocklyInputsDropdown)?.getValue();
  const dropdownAction = this.getField(blocklyFunctionsDropdown)?.getValue();

  const dropdownValue: BlocklyInputParameterDropdownValues = JSON.parse(dropdownParameter);

  const code = `${parametersVariableName}['${dropdownValue.name}'].${dropdownAction}`;
  return [code, javascriptGenerator.ORDER_NONE];
};

javascriptGenerator[commentBlock] = function (this: BlockSvg) {
  const comment = this.getField(blocklyCommentBlockName)?.getValue();
  return `/* ${comment} */`;
};

// This is set to prevent user of overriding the codeRunner functions/variables/constants
javascriptGenerator.addReservedWords(
  `${loopTrapVariableName},${parametersVariableName},${codeRunnerGetInput},${codeRunnerSetOutput},${HIGHLIGHT_CODE_BLOCK}`,
);
javascriptGenerator.addReservedWords('highlightBlock');

// This generates code that will keep a counter of the number of loops
// that have been executed. If the counter exceeds a certain value
// an infinite loop is assumed and the execution is interrupted.
javascriptGenerator.INFINITE_LOOP_TRAP = INFINITE_LOOP_TRAP_CODE;
