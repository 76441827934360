import { ProductDefinitionInputParameter, PublishStatus } from 'mid-addin-lib';
import { CodeblocksWorkspaceType } from '../../components/Rules/types';
import { create } from 'zustand';

export const initialPublishStatus = PublishStatus.IDLE;

export type InventorDataStore = {
  productDefinitionHasUnsavedChanges: boolean;
  currentPublishStatus: PublishStatus;
  isErronousProductDefinitionName: {
    error: boolean;
    cause: string | null;
  };
  latestWorkspaceSelected: CodeblocksWorkspaceType | undefined;
  isPublishingDisabled: boolean;
  recentlyAdoptedInputs: ProductDefinitionInputParameter[];
  backpackContents: string[];
  areDrawingFilesFetched: boolean;
  publishingDisabledCause: string | null;
  numberOfProductDefinitions: number;
  isProductFormPreviewILogicAlertOpen: boolean;
  publishPrerequisiteErrors: {
    formError: boolean;
    productDefinitionDefaultValuesError: boolean;
    inventorModelDefaultValuesError: boolean;
    documentSavedError: boolean;
  };
};

export const useInventorDataStore = create<InventorDataStore>(() => ({
  currentPublishStatus: initialPublishStatus,
  isErronousProductDefinitionName: {
    error: false,
    cause: null,
  },
  productDefinitionHasUnsavedChanges: false,
  latestWorkspaceSelected: CodeblocksWorkspaceType.FORM,
  isPublishingDisabled: false,
  recentlyAdoptedInputs: [],
  backpackContents: [],
  areDrawingFilesFetched: false,
  publishingDisabledCause: null,
  numberOfProductDefinitions: 0,
  isProductFormPreviewILogicAlertOpen: true,
  publishPrerequisiteErrors: {
    formError: false,
    productDefinitionDefaultValuesError: false,
    inventorModelDefaultValuesError: false,
    documentSavedError: false,
  },
}));

export const inventorStoreActions = {
  setCurrentPublishStatus: (currentPublishStatus: PublishStatus): void =>
    useInventorDataStore.setState({ currentPublishStatus }),
  setIsErronousProductDefinitionName: (isErronousProductDefinitionName: { error: boolean; cause: string | null }): void =>
    useInventorDataStore.setState({ isErronousProductDefinitionName }),
  setLatestWorkspaceSelected: (latestWorkspaceSelected: CodeblocksWorkspaceType | undefined): void =>
    useInventorDataStore.setState({ latestWorkspaceSelected }),
  setRecentlyAdoptedInputs: (recentlyAdoptedInputs: ProductDefinitionInputParameter[]): void =>
    useInventorDataStore.setState({ recentlyAdoptedInputs }),
  setAreDrawingFilesFetched: (areDrawingFilesFetched: boolean): void =>
    useInventorDataStore.setState({ areDrawingFilesFetched }),
  setBackpackContents: (backpackContents: string[]): void => useInventorDataStore.setState({ backpackContents }),
  setIsPublishingDisabled: (isPublishingDisabled: boolean): void => useInventorDataStore.setState({ isPublishingDisabled }),
  setProductDefinitionHasUnsavedChanges: (productDefinitionHasUnsavedChanges: boolean): void =>
    useInventorDataStore.setState({ productDefinitionHasUnsavedChanges }),
  setPublishingDisabledCause: (publishingDisabledCause: string | null): void =>
    useInventorDataStore.setState({ publishingDisabledCause }),
  setPublishPrerequisiteErrors: (publishPrerequisiteErrors: InventorDataStore['publishPrerequisiteErrors']): void =>
    useInventorDataStore.setState({ publishPrerequisiteErrors }),
  setIsProductFormPreviewILogicAlertOpen: (isProductFormPreviewILogicAlertOpen: boolean): void =>
    useInventorDataStore.setState({ isProductFormPreviewILogicAlertOpen }),
  setNumberOfProductDefinitions: (numberOfProductDefinitions: number): void =>
    useInventorDataStore.setState({ numberOfProductDefinitions }),
};
