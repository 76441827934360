import { DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { ProductDefinitionInputParameter } from 'mid-addin-lib';
import { useState } from 'react';
import { productDefinitionActions } from '../../../../../context/DataStore/productDefinitionStore';

interface useInputsSidebarArgs {
  selectedInputs: ProductDefinitionInputParameter[];
}

interface useInputsSidebarState {
  currentDraggingInput: UniqueIdentifier;
  handleDragStart: (event: DragStartEvent) => void;
  handleInputsDragEnd: (event: DragEndEvent) => void;
}

export const useInputsSidebar = ({ selectedInputs }: useInputsSidebarArgs): useInputsSidebarState => {
  const [currentDraggingInput, setCurrentDraggingInput] = useState<UniqueIdentifier>('');

  const handleInputsDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = selectedInputs.findIndex((parameter) => parameter.name === active.id);
      const newIndex = selectedInputs.findIndex((parameter) => parameter.name === over.id);
      // Update parameters in data store
      productDefinitionActions.replaceAllInputs(arrayMove(selectedInputs, oldIndex, newIndex));
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setCurrentDraggingInput(event.active.id);
  };

  return {
    currentDraggingInput,
    handleDragStart,
    handleInputsDragEnd,
  };
};
