import Typography from '@mui/material/Typography';
import { EMPTY_STATE_ILLUSTRATION_TYPES, FlexContainer, MIDEmptyState } from '@mid-react-common/common';
import { Dropdown, DropdownItem } from '@mid-react-common/addins';
import React from 'react';
import text from 'inventor.text.json';
import { RedirectLink, Drawing2DPreviewWrapper, Drawing2DPreview, Drawing2DEmptyStateWrapper } from './Drawing2D.styles';
import { DrawingThumbnail } from 'mid-types';
import { drawingThumbnailsTestIds } from 'tests/helpers/dataTestIds';
import { useTheme } from '@mui/material/styles';

interface DrawingSelectionPreviewProps {
  drawingThumbnailsDropdownElements: DropdownItem[];
  selectedDrawingDropdownElement: DropdownItem | null;
  selectedDrawingThumbnailPreview: DrawingThumbnail | undefined;
  handleDrawingThumbnailSelectionPreview(item: { selectedItem?: DropdownItem | null }): void;
  handleTabRedirectToSourceContent: () => void;
}

const Drawing2DSelectionPreview: React.FC<DrawingSelectionPreviewProps> = ({
  drawingThumbnailsDropdownElements,
  selectedDrawingDropdownElement,
  selectedDrawingThumbnailPreview,
  handleDrawingThumbnailSelectionPreview,
  handleTabRedirectToSourceContent,
}) => {
  const theme = useTheme();

  return (
    <FlexContainer flexDirection="column">
      {!drawingThumbnailsDropdownElements.length && (
        <RedirectLink
          component="button"
          data-testid={drawingThumbnailsTestIds.redirectLinkToSourceContent}
          onClick={handleTabRedirectToSourceContent}
        >
          {text.drawingTableRedirectToOutputSources}
        </RedirectLink>
      )}
      <Typography variant="body1" paddingY={1}>
        {text.outputsDrawing2DPreview}
      </Typography>
      {drawingThumbnailsDropdownElements.length ? (
        <>
          <Dropdown
            elements={drawingThumbnailsDropdownElements}
            selectedItem={selectedDrawingDropdownElement}
            onSelect={handleDrawingThumbnailSelectionPreview}
            width={`${theme.var.outputTabInputWidth}px`}
          />
          {selectedDrawingThumbnailPreview && (
            <Drawing2DPreviewWrapper>
              <Drawing2DPreview
                src={`data:image/png;base64, ${selectedDrawingThumbnailPreview.base64}`}
                data-testid={drawingThumbnailsTestIds.previewImage}
              />
            </Drawing2DPreviewWrapper>
          )}
        </>
      ) : (
        <Drawing2DEmptyStateWrapper variant="outlined" square className="mid-bg-shadow">
          <MIDEmptyState
            hideTitle
            description={text.outputsDrawing2DEmptyStateMessage}
            illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.EMPTY_BOX}
          ></MIDEmptyState>
        </Drawing2DEmptyStateWrapper>
      )}
    </FlexContainer>
  );
};

export default Drawing2DSelectionPreview;
