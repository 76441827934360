import { GridColDef } from '@mui/x-data-grid';
import text from 'inventor.text.json';

export const getParameterTableColumns = (): GridColDef[] => [
  {
    field: text.inputsTableColumnNameId,
    headerName: text.inputsTableColumnNameHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnParameterTypeId,
    headerName: text.inputsTableColumnParameterTypeHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnUnitId,
    headerName: text.inputsTableColumnUnitHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnValueId,
    headerName: text.inputsTableColumnDefaultValueHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnIsKeyId,
    headerName: text.inputsTableColumnIsKeyHeader,
  },
];

export const adoptedTooltipLink = 'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_HelpTopics_3_param_adoption_html';
