import Divider from '@mui/material/Divider';
import { useEffect, useRef, useState } from 'react';
import { BlocklyWrapper } from './Rules.styles';
import { ProductFormPreview } from './ProductFormPreview/ProductFormPreview';
import FormCodeblocks from './FormCodeblocks/FormCodeblocks';
import { CodeblocksWorkspaceType } from './types';
import WorkspaceSelector from './Components/WorkspaceSelector';
import { getSelectedWorkspace } from './utils';
import InputCodeblocks from './InputCodeblocks/InputCodeblocks';
import { productDefinitionActions, useProductDefinitionStore } from '../../context/DataStore/productDefinitionStore';
import { inventorStoreActions, useInventorDataStore } from '../../context/DataStore/InventorDataStore';
import { useShallow } from 'zustand/react/shallow';

const Rules: React.FC = (): JSX.Element => {
  const { productDefinitionName, topLevelFolder, codeBlocksWorkspace, formCodeBlocksWorkspace } = useProductDefinitionStore(
    useShallow((state) => ({
      productDefinitionName: state.name,
      topLevelFolder: state.topLevelFolder,
      codeBlocksWorkspace: state.codeBlocksWorkspace,
      formCodeBlocksWorkspace: state.formCodeBlocksWorkspace,
    })),
  );

  const { recentlyAdoptedInputs, latestWorkspaceSelected, backpackContents } = useInventorDataStore(
    useShallow((state) => ({
      recentlyAdoptedInputs: state.recentlyAdoptedInputs,
      latestWorkspaceSelected: state.latestWorkspaceSelected,
      backpackContents: state.backpackContents,
    })),
  );

  const selectedWorkspace = getSelectedWorkspace(latestWorkspaceSelected);

  const inputsRef = useRef(useProductDefinitionStore.getState().inputs);
  useEffect(() => useProductDefinitionStore.subscribe((state) => (inputsRef.current = state.inputs)), []);

  const [areRulesLoaded, setAreRulesLoaded] = useState(false);
  const [updateFormEnabled, setUpdateFormEnabled] = useState(false);
  const [highlightedBlockId, setHighlightedBlockId] = useState<string | undefined>(undefined);
  const getCodeRef = useRef<(() => string) | undefined>();

  return (
    <BlocklyWrapper>
      <WorkspaceSelector
        selectedWorkspace={selectedWorkspace}
        setSelectedWorkspace={inventorStoreActions.setLatestWorkspaceSelected}
      />
      <InputCodeblocks
        inputCodeblocksWorkspace={codeBlocksWorkspace}
        hidden={selectedWorkspace !== CodeblocksWorkspaceType.INPUT}
        inputs={inputsRef.current}
        backpackContents={backpackContents}
        productDefinitionName={productDefinitionName}
        productDefinitionTopLevelFolder={topLevelFolder}
        highlightedBlockId={highlightedBlockId}
        setInputsCodeBlocksRules={productDefinitionActions.setRule}
        setInputsCodeBlocksWorkspace={productDefinitionActions.setInputCodeBlocksWorkspace}
        setBackpackContents={inventorStoreActions.setBackpackContents}
        setUpdateFormEnabled={setUpdateFormEnabled}
        setAreRulesLoaded={setAreRulesLoaded}
        getCodeRef={getCodeRef}
      />
      <FormCodeblocks
        hidden={selectedWorkspace !== CodeblocksWorkspaceType.FORM}
        inputs={inputsRef.current}
        formCodeblocksWorkspace={formCodeBlocksWorkspace}
        recentlyAdoptedInputs={recentlyAdoptedInputs}
        setFormCodeBlocksWorkspace={productDefinitionActions.setFormCodeBlocksWorkspace}
        setFormCodeBlocksRules={productDefinitionActions.setRule}
        setRecentlyAdoptedInputs={inventorStoreActions.setRecentlyAdoptedInputs}
      />
      <Divider orientation="vertical" />
      <ProductFormPreview
        updateFormEnabled={updateFormEnabled}
        selectedWorkspace={selectedWorkspace}
        areRulesLoaded={areRulesLoaded}
        getCodeRef={getCodeRef}
        setHighlightedBlockId={setHighlightedBlockId}
        setUpdateFormEnabled={setUpdateFormEnabled}
      />
    </BlocklyWrapper>
  );
};

export default Rules;
