import { GridSelectionModel } from '@mui/x-data-grid';
import { ProductDefinitionInputParameter } from 'mid-addin-lib';
import { useEffect, useState } from 'react';

export const useTransformToTableIds = (items: ProductDefinitionInputParameter[]): GridSelectionModel => {
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);

  useEffect(() => {
    const ids: GridSelectionModel = items.map((item) => item.name);
    setSelectedIds(ids);
  }, [items]);

  return selectedIds;
};
