import { useEffect } from 'react';
import { InventorProperties } from 'mid-addin-lib';
import { productDefinitionActions, useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { useShallow } from 'zustand/react/shallow';

type UseParametersDefaults = {
  inventorData: InventorProperties | undefined;
};

const useParametersDefaults = ({ inventorData }: UseParametersDefaults): void => {
  const parameterDefaults = useProductDefinitionStore(useShallow((state) => state.parametersDefaults));

  // initialize parameter defaults (one time operation)
  useEffect(() => {
    if (!inventorData) {
      return;
    }

    const parametersDefaultsNotSet = !parameterDefaults || !parameterDefaults.length;

    if (parametersDefaultsNotSet) {
      productDefinitionActions.setParametersDefaultsByInventorParameters(inventorData.parameters);
    }
  }, [inventorData, parameterDefaults]);

  // replace the inventorData parameters values with their defaults if applicable
  useEffect(() => {
    if (!inventorData) {
      return;
    }

    if (!parameterDefaults || !parameterDefaults.length) {
      return;
    }

    const parameterDefaultsMap = new Map(
      parameterDefaults.map((parameterDefault) => [parameterDefault.name, parameterDefault.value]),
    );

    inventorData.parameters.forEach((parameter) => {
      if (!parameterDefaultsMap.has(parameter.name)) {
        return;
      }

      const parameterDefault = parameterDefaultsMap.get(parameter.name);
      if (parameterDefault) {
        parameter.value = String(parameterDefault);
      }
    });
  }, [inventorData, parameterDefaults]);
};

export default useParametersDefaults;
