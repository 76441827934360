import TextareaAutosize from '@mui/material/TextareaAutosize';
import Divider from '@mui/material/Divider';
import { styled, keyframes } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { midTheme } from '@mid-react-common/common';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

export const Wrapper = styled('div')`
  margin: auto;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.var.publishHeaderHeight}px`} - ${({ theme }) => `${theme.var.paddingBase * 2 * 2}px`}
  );
  overflow: auto;
  padding: ${({ theme }) => `0 ${theme.var.paddingBase * 4}px`};
`;

export const ProductDefinitionSummary = styled('div')`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`} 0;
`;

export const DividerWrapper = styled(Divider)`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`} 0;
  width: 50%;
`;

export const PublishTableContainer = styled('div')`
  border-radius: ${({ theme }) => `${theme.var.borderRadius}px`};
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const ReleaseNotesTextarea = styled(TextareaAutosize)`
  min-width: ${({ theme }) => `${theme.var.midTreeMinWidth * 2 + theme.var.marginBase}px`};
`;

export const productFolderBrowserHeight =
  midTheme.var.headerHeight +
  midTheme.var.paddingBase * 2 * 2 +
  midTheme.var.releaseDetailsHeight +
  midTheme.var.publishLocationHeight +
  midTheme.var.marginBase * 2 +
  midTheme.var.productFolderBrowserGridHeaderHeight;

export const ProductName = styled(TextField)`
  width: ${({ theme }) => theme.var.midTreeMinWidth}px;
`;

export const ReleaseNotesWrapper = styled('div')`
  width: ${({ theme }) => `${theme.var.midTreeMinWidth * 2 + theme.var.marginBase}px`};
`;

export const SectionTitle = styled(Typography)`
  font-weight: bold;
`;

export const ReleaseToBeTitle = styled('span')`
  font-weight: bold;
  margin-right: 5px;
`;

export const ReleaseToBeContainer = styled('div')`
  margin-left: ${({ theme }) => theme.var.marginBase}px;
`;

const threeDotsAnimation = keyframes`
  to {
    clip-path: inset(0 -1ch 0 0);
  }
`;

export const ThreeDotsLoader = styled('span')`
  display: inline-block;
  font-family: monospace;
  clip-path: inset(0 3ch 0 0);
  animation: ${threeDotsAnimation} 1s steps(4) infinite;
`;

export const AccordionWrapper = styled(Accordion)`
  padding: ${({ theme }) => `${theme.var.paddingBase * 1.5}px`};
  max-width: 60%;
  margin-inline: auto !important;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

export const ListWrapper = styled(List)`
  list-style-type: disc;
`;

export const ListItemWrapper = styled(ListItem)`
  display: list-item;
`;

export const PrerequisitesWrapper = styled(Box)`
  height: calc(
    100vh -
      ${({ theme }) =>
        theme.var.headerHeight + theme.var.paddingBase * 2 + theme.var.publishingPrerequisitesTitle + theme.var.gapBase}px
  );
  width: 100%;
  overflow: auto;
`;
