export const bimDefinitionLearnMoreLink =
  'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_Get_Started_bim_def_addin_html';

export const revitFamilyCategories = [
  'Abutments - Abutment Foundations',
  'Abutments - Abutment Piles',
  'Abutments - Abutment Walls',
  'Abutments - Approach Slabs',
  'Abutments',
  'Air Terminals',
  'Audio Visual Devices',
  'Bearings',
  'Bridge Cables',
  'Bridge Decks',
  'Bridge Framing - Arches',
  'Bridge Framing - Cross Bracing',
  'Bridge Framing - Diaphragms',
  'Bridge Framing - Girders',
  'Bridge Framing - Trusses',
  'Bridge Framing',
  'Cable Tray Fittings',
  'Casework',
  'Columns',
  'Communication Devices',
  'Conduit Fittings',
  'Data Devices',
  'Doors',
  'Duct Accessories',
  'Duct Fittings',
  'Electrical Equipment',
  'Electrical Fixtures',
  'Entourage',
  'Expansion Joints',
  'Fire Alarm Devices',
  'Fire Protection',
  'Food Service Equipment',
  'Furniture Systems',
  'Furniture',
  'Generic Models',
  'Hardscape',
  'Lighting Devices',
  'Lighting Fixtures',
  'Mass',
  'Mechanical Control Devices',
  'Mechanical Equipment',
  'Medical Equipment',
  'Nurse Call Devices',
  'Parking',
  'Piers - Pier Caps',
  'Piers - Pier Columns',
  'Piers - Pier Foundations',
  'Piers - Pier Piles',
  'Piers - Pier Towers',
  'Piers - Pier Walls',
  'Piers',
  'Pipe Accessories',
  'Pipe Fittings',
  'Planting',
  'Plumbing Equipment',
  'Plumbing Fixtures',
  'Railings - Supports',
  'Railings - Terminations',
  'Railings',
  'Roads',
  'Security Devices',
  'Signage',
  'Site',
  'Specialty Equipment',
  'Sprinklers',
  'Structural Columns',
  'Structural Connections',
  'Structural Foundations',
  'Structural Framing',
  'Structural Stiffeners',
  'Structural Tendons',
  'Telephone Devices',
  'Temporary Structures',
  'Vertical Circulation',
  'Vibration Management - Vibration Dampers',
  'Vibration Management - Vibration Isolators',
  'Vibration Management',
  'Windows',
];
