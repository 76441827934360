import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import text from 'inventor.text.json';
import DrawingExportRows from './DrawingExportRows';
import { IconWrapper } from '@mid-react-common/common';
import { OutputRepresentationsTable } from '../../OutputsTab.styles';
import { drawingThumbnailsTestIds } from 'tests/helpers/dataTestIds';
import { DrawingThumbnail } from 'mid-types';

interface DrawingTableProps {
  showSelectedDrawings: boolean;
  selectedDrawingThumbnailInDropdown: DrawingThumbnail | undefined;
}

const DrawingTable: React.FC<DrawingTableProps> = ({ showSelectedDrawings, selectedDrawingThumbnailInDropdown }) => {
  const noOutputRow = (
    <TableRow data-testid={drawingThumbnailsTestIds.noDrawingThumbnailOutputRow}>
      <TableCell>
        <IconWrapper>
          <ReportProblemIcon color="error" />
        </IconWrapper>
        <span>{text.drawingTableNoOutput}</span>
      </TableCell>
      <TableCell>{text.drawingTableNoOutputType}</TableCell>
      <TableCell align="right">
        <FormControlLabel
          labelPlacement="start"
          label={text.outputUnavailable}
          control={<Switch disabled checked={false} />}
        />
      </TableCell>
    </TableRow>
  );

  return (
    <OutputRepresentationsTable size="small" data-testid={drawingThumbnailsTestIds.outputsTable}>
      <TableHead>
        <TableRow>
          <TableCell>{text.drawingsTableHeaderName}</TableCell>
          <TableCell>{text.drawingsTableHeaderType}</TableCell>
          <TableCell align="right">{text.outputStatus}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {showSelectedDrawings ? (
          <DrawingExportRows selectedDrawingThumbnailInDropdown={selectedDrawingThumbnailInDropdown} />
        ) : (
          noOutputRow
        )}
      </TableBody>
    </OutputRepresentationsTable>
  );
};

export default DrawingTable;
