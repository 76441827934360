import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid';
import text from 'inventor.text.json';
import { InventorProperties, getPartOrAssemblyProperties } from 'mid-addin-lib';

export const fetchAllInventorData = async (iamFileFullPath: string): Promise<InventorProperties> =>
  getPartOrAssemblyProperties(iamFileFullPath);

export const filterParameters = (filter: string): GridFilterModel => {
  let filterItem: GridFilterItem = {
    columnField: '',
  };
  switch (filter) {
    case text.inputsFilterOptionAll:
      filterItem = { columnField: '', operatorValue: '', value: '' };
      break;
    case text.inputsFilterOptionKey:
      filterItem = { columnField: 'isKey', operatorValue: text.MUITableColumnFilterEquals, value: 'true' };
      break;
    case text.inputsFilterOptionModel:
    case text.inputsFilterOptionUser:
      filterItem = {
        columnField: text.inputsTableColumnParameterTypeId,
        operatorValue: text.MUITableColumnFilterEquals,
        value: filter,
      };
      break;
    case text.inputsFilterOptionSelected:
      filterItem = { columnField: '__check__', operatorValue: text.MUITableColumnFilterIs, value: 'true' };
      break;
    default:
      break;
  }
  return { items: [filterItem] };
};
