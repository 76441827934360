import { BlocklyPlaceholder } from '../Rules.styles';
import { InputRule, ProductDefinitionInputParameter, SerializedBlocklyWorkspaceState } from 'mid-addin-lib';
import inventorTestIds from '../../../inventor.testids';
import useInputCodeblocks from './useInputCodeblocks';
import { MutableRefObject } from 'react';

interface InputCodeblocksProps {
  hidden: boolean;
  inputs: ProductDefinitionInputParameter[];
  inputCodeblocksWorkspace?: SerializedBlocklyWorkspaceState;
  setInputsCodeBlocksWorkspace: (workspace: SerializedBlocklyWorkspaceState) => void;
  setInputsCodeBlocksRules: (rule: InputRule) => void;
  backpackContents: string[];
  setBackpackContents: (backpackContents: string[]) => void;
  productDefinitionName: string;
  productDefinitionTopLevelFolder: string;
  setUpdateFormEnabled: (enabled: boolean) => void;
  highlightedBlockId?: string;
  setAreRulesLoaded: (loaded: boolean) => void;
  getCodeRef: MutableRefObject<(() => string) | undefined>;
}

const { blocklyInputsWorkspaceTestId } = inventorTestIds;

const InputCodeblocks: React.FC<InputCodeblocksProps> = ({ inputCodeblocksWorkspace, hidden, ...props }): JSX.Element => {
  const { ref } = useInputCodeblocks({
    initialState: inputCodeblocksWorkspace,
    hidden,
    ...props,
  });

  return <BlocklyPlaceholder data-testid={blocklyInputsWorkspaceTestId} ref={ref} hidden={hidden} />;
};

export default InputCodeblocks;
