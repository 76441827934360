import Typography from '@mui/material/Typography';
import { EMPTY_STATE_ILLUSTRATION_TYPES, MIDEmptyState, SpinnerForButton } from '@mid-react-common/common';
import text from '../../../../inventor.text.json';
import { SourceContentPreviewImage, SpinnerWrapper, ThumbnailContainer } from '../SourceContentTab.styles';
import { previewThumbnailTestIds } from 'tests/helpers/dataTestIds';
import SyncIcon from '@mui/icons-material/Sync';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

interface PreviewThumbnailProps {
  thumbnailInBase64: { name: string; base64: string } | undefined;
  isThumbnailLoading: boolean;
  handleRefreshThumbnail: () => void;
}

const PreviewThumbnail: React.FC<PreviewThumbnailProps> = ({
  thumbnailInBase64,
  isThumbnailLoading,
  handleRefreshThumbnail,
}): JSX.Element => (
  <div>
    <Typography variant="h2" gutterBottom display="inline">
      {text.sourceContentThumbnail}
    </Typography>
    <Tooltip title={text.sourceContentThumbnailRefresh}>
      <IconButton onClick={handleRefreshThumbnail} size="small">
        <SyncIcon />
      </IconButton>
    </Tooltip>
    <ThumbnailContainer>
      {isThumbnailLoading ? (
        <SpinnerWrapper>
          <SpinnerForButton />
        </SpinnerWrapper>
      ) : thumbnailInBase64 ? (
        <SourceContentPreviewImage
          data-testid={previewThumbnailTestIds.previewThumbnail}
          src={`data:image/jpeg;base64,${thumbnailInBase64!.base64}`}
          alt={text.previewImageAltText}
        />
      ) : (
        <MIDEmptyState
          title={text.notificationThumbnailFailed}
          illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.NO_RESULTS}
        />
      )}
    </ThumbnailContainer>
  </div>
);

export default PreviewThumbnail;
