import '@blockly/theme-dark';
import '@blockly/theme-modern';
import Blockly from 'blockly';
import { midTheme as theme } from '@mid-react-common/common';
import { CONTEXT_MENU_DUPLICATE_CONNECTED, CONTEXT_MENU_EXPORT, CONTEXT_MENU_IMPORT } from './constants';

export const lightTheme = Blockly.Theme.defineTheme('midLightTheme', {
  base: 'modern',
  name: 'midLightTheme',
  componentStyles: {
    workspaceBackgroundColour: theme.colors.surface.lightGray[10],
    toolboxBackgroundColour: theme.colors.surface.lightGray[20],
    flyoutBackgroundColour: theme.colors.surface.lightGray[30],
  },
});

export const darkTheme = Blockly.Theme.defineTheme('midDarkTheme', {
  base: 'dark',
  name: 'midDarkTheme',
  componentStyles: {
    workspaceBackgroundColour: theme.colors.surface.darkBlue[20],
    toolboxBackgroundColour: theme.colors.surface.darkBlue[25],
    flyoutBackgroundColour: theme.colors.surface.darkBlue[30],
    scrollbarColour: theme.colors.surface.darkBlue[35],
  },
});

const blocklyConfig = {
  collapse: true,
  comments: true,
  css: true,
  disable: true,
  horizontal: false,
  maxBlocks: Infinity,
  oneBasedIndex: true,
  readOnly: false,
  renderer: 'thrasos',
  rtl: false,
  scrollbars: true,
  sounds: true,
  toolboxPosition: 'start',
  trashcan: true,
  zoom: {
    controls: true,
  },
  plugins: {
    [CONTEXT_MENU_DUPLICATE_CONNECTED]: CONTEXT_MENU_DUPLICATE_CONNECTED,
    [CONTEXT_MENU_IMPORT]: CONTEXT_MENU_IMPORT,
    [CONTEXT_MENU_EXPORT]: CONTEXT_MENU_EXPORT,
  },
};

export default blocklyConfig;
