type EventEmitter = {
  _events: Partial<Record<string, Array<(data: any) => any>>>;
  dispatch: (event: string, data?: any) => void;
  subscribe: (event: string, callback: (data: any) => any) => void;
  unsubscribe: (event: string) => void;
};

const eventEmitter: EventEmitter = {
  _events: {},
  dispatch(event: string, data?: any) {
    if (!this._events[event]) {
      return;
    }
    this._events[event]!.forEach((callback) => callback(data));
  },
  subscribe(event: string, callback: (data?: any) => any) {
    if (!this._events[event]) {
      this._events[event] = [];
    }
    this._events[event]!.push(callback);
  },
  unsubscribe(event: string) {
    if (!this._events[event]) {
      return;
    }
    delete this._events[event];
  },
};

export default eventEmitter;
