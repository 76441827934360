import Tooltip from '@mui/material/Tooltip';
import text from '../../../inventor.text.json';
import IconButton from '@mui/material/IconButton';
import UpdateFormIcon from './icons/UpdateFormIcon';
import ResetToDefaultsIcon from './icons/ResetToDefaultsIcon';
import { FlexSpacer } from '@mid-react-common/common';
import GetModelValuesIcon from './icons/GetModelValuesIcon';
import SetModelValuesIcon from './icons/SetModelValues';
import inventorTestIds from '../../../inventor.testids';

const { updateFormButtonTestId, setModelValuesButtonTestId, resetToDefaultsButtonTestId, getModelValuesButtonTestId } =
  inventorTestIds;
interface InputWorkspaceControlsProps {
  handleUpdateForm: () => void;
  handleSetModelValues: () => void;
  handleResetToDefaults: () => void;
  handleGetModelValues: () => void;
  updateFormEnabled: boolean;
  hasInputsError?: boolean;
}

export const InputWorkspaceControls: React.FC<InputWorkspaceControlsProps> = ({
  handleUpdateForm,
  handleSetModelValues,
  handleResetToDefaults,
  handleGetModelValues,
  updateFormEnabled,
  hasInputsError,
}) => (
  <>
    <Tooltip title={text.blocklyUpdateFormButton}>
      <IconButton
        aria-label={text.blocklyUpdateFormButton}
        onClick={handleUpdateForm}
        data-testid={updateFormButtonTestId}
        disabled={!updateFormEnabled}
      >
        <UpdateFormIcon disabled={!updateFormEnabled} />
      </IconButton>
    </Tooltip>
    <Tooltip title={text.blocklyResetToDefaultsButton}>
      <IconButton
        aria-label={text.blocklyResetToDefaultsButton}
        onClick={handleResetToDefaults}
        data-testid={resetToDefaultsButtonTestId}
      >
        <ResetToDefaultsIcon />
      </IconButton>
    </Tooltip>
    <FlexSpacer />
    <Tooltip title={text.blocklyGetModelValuesButton}>
      <IconButton
        aria-label={text.blocklyGetModelValuesButton}
        onClick={handleGetModelValues}
        data-testid={getModelValuesButtonTestId}
      >
        <GetModelValuesIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={text.blocklySetModelValuesButton}>
      <IconButton
        aria-label={text.blocklySetModelValuesButton}
        onClick={handleSetModelValues}
        data-testid={setModelValuesButtonTestId}
        disabled={hasInputsError}
      >
        <SetModelValuesIcon disabled={hasInputsError} />
      </IconButton>
    </Tooltip>
  </>
);
