import text from '../../inventor.text.json';
import {
  ProductsUtils,
  PublishStatus,
  ProductDefinitionsCrudUtils,
  ProductDefinitionPublishResult,
  publishProductDefinition,
  browserApiService,
  getFullFolderPath,
} from 'mid-addin-lib';
import {
  NOTIFICATION_STATUSES,
  NotificationContext,
  useLogAndShowNotification,
  useCancellablePromise,
  useAccBridge,
} from '@mid-react-common/common';
import { TreeItem } from '@mid-react-common/addins';
import { useCallback, useContext, useEffect, useState, ReactNode } from 'react';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { validateProductName } from '../../utils/productDefinition';
import { ampli } from '../../ampli';
import { DynamicContent } from '@adsk/offsite-dc-sdk';
import {
  FOLDERS_PERMISSION_FILTER_OPTIONS,
  FolderDMPermissionAction,
  MetaInfo,
  MetaInfoPath,
  accProjectPlatform,
} from 'mid-types';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { isNumericInput, EventEmitter } from 'mid-utils';
import { formRulesKey } from '../Rules/FormCodeblocks/FormCodeblocks.constants';
import Events from '../../utils/eventEmitterEvents';
import { useQuery } from '@tanstack/react-query';
import useReleaseNumberToBe from './useReleaseNumberToBe';
import { productDefinitionActions, useProductDefinitionStore } from '../../context/DataStore/productDefinitionStore';
import { inventorStoreActions } from '../../context/DataStore/InventorDataStore';
import { useShallow } from 'zustand/react/shallow';
import { useProductNameUniquenessValidation } from './ProductNameUniqueness/useProductNameUniquenessValidation';

interface UsePublishingProps {
  selectedAccount: MetaInfo | undefined;
  selectedProject: MetaInfo | undefined;
}
export interface UsePublishingState {
  products: DynamicContent[] | undefined;
  productsLoading: boolean;
  incomingAccBridgeProducts: DynamicContent[] | undefined;
  bridgeProductsLoading: boolean;
  productsError: Error | null;
  rootFoldersTreeItems: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeItem: TreeItem | undefined;
  productNameToPublish: string;
  releaseNumberToBe: number | undefined;
  handleSelectFolder: (item: TreeItem, path: MetaInfo[]) => void;
  handleNewProductDefinitionClick: () => void;
  handleOpenProductDefinitionsSelectionClick: () => void;
  handleProductNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleProductNameDoubleClick: (product: DynamicContent) => void;
  handlePublishClick: () => void;
  publishResponse: ProductDefinitionPublishResult | undefined;
  publishFolderPermission: FolderDMPermissionAction;
  productNameErrors: string | null;
  productNameIconErrorText: ReactNode;
  onReleaseNotesChange: (value: string, isValid: boolean) => void;
  shouldShowReleaseNumberToBe: boolean;
  releaseNumberToBeLoading: boolean;
}

const publishFolderPermission = FOLDERS_PERMISSION_FILTER_OPTIONS.publish;
const GET_ALL_PRODUCTS_IN_PROJECT_BEFORE_PUBLISH_KEY = 'getAllProductsInProjectBeforePublish';
const GET_BRIDGE_PRODUCTS = 'getBridgeProducts';

export const usePublishing = ({ selectedAccount, selectedProject }: UsePublishingProps): UsePublishingState => {
  const currentProductDefinition = useProductDefinitionStore.getState();

  const { name, releaseName, folder } = useProductDefinitionStore(
    useShallow((state) => ({
      name: state.name,
      releaseName: state.releaseName,
      folder: state.folder,
    })),
  );

  const { setCurrentScreen } = useContext(NavigationContext);
  const { showNotification } = useContext(NotificationContext);

  const { incomingBridgeFoldersMap } = useAccBridge({
    projectId: selectedProject?.id || null,
    isAccProject: selectedProject?.platform === accProjectPlatform.acc,
  });

  const [isRootProjectFilesSelected, setIsRootProjectFilesSelected] = useState(false);
  const { enableMultiValuesBackwardsCompatibility, enforceRevitClassification, enableAccBridge } = useFlags();

  const [productNameToPublish, setProductNameToPublish] = useState(releaseName || name);

  const [rootFoldersTreeItems, setRootFoldersDropdownItems] = useState<TreeItem[]>([]);
  const [selectedFolderTreeItem, setSelectedFolderDropdownItem] = useState<TreeItem | undefined>();
  const isSelectedFolderTreeItemIncomingBridgeFolder = selectedFolderTreeItem
    ? !!incomingBridgeFoldersMap?.get(selectedFolderTreeItem?.id)
    : false;
  const isSelectedFolderAnActiveIncomingBridgeFolder = selectedFolderTreeItem
    ? !!incomingBridgeFoldersMap?.get(selectedFolderTreeItem?.id)?.isSyncAutomationActive
    : false;

  const [publishResponse, setPublishResponse] = useState<ProductDefinitionPublishResult | undefined>();

  const [releaseNotesIsValid, setReleaseNotesIsValid] = useState(true);

  const cancellablePromise = useCancellablePromise();

  const { data: token } = useQuery({
    queryKey: ['token'],
    queryFn: async () => browserApiService.getOAuth2Token(),
  });

  const {
    data: products,
    error: productsError,
    isFetching: productsLoading,
  } = useQuery({
    queryKey: [GET_ALL_PRODUCTS_IN_PROJECT_BEFORE_PUBLISH_KEY, selectedProject?.id],
    queryFn: async ({ queryKey: [, projectId], signal }) => {
      if (projectId) {
        return ProductsUtils.getAllProductsInProject({ projectId, enableMultiValuesBackwardsCompatibility }, signal);
      }
    },
    enabled: Boolean(token && selectedProject?.id),
    initialData: undefined,
  });

  useLogAndShowNotification(productsError, text.notificationGetProductsFailed);

  const {
    data: bridgeProducts,
    error: bridgeProductsError,
    isFetching: bridgeProductsLoading,
  } = useQuery({
    queryKey: [GET_BRIDGE_PRODUCTS, selectedProject?.id, selectedFolderTreeItem?.id],
    queryFn: async ({ signal }) =>
      selectedProject && selectedFolderTreeItem
        ? ProductsUtils.getAllProductsInBridgeFolder(
            { projectId: selectedProject.id, targetFolderUrn: selectedFolderTreeItem.id },
            signal,
          )
        : undefined,
    enabled: isSelectedFolderTreeItemIncomingBridgeFolder,
  });

  useLogAndShowNotification(bridgeProductsError, text.notificationGetProductsFailed);

  const { releaseNumberToBe, releaseNumberToBeLoading, shouldShowReleaseNumberToBe } = useReleaseNumberToBe({
    productNameToPublish,
    projectId: selectedProject?.id,
    products,
    productsError,
    productsLoading,
  });

  const {
    data: rootFolders,
    error: rootFoldersError,
    isFetching: rootFoldersLoading,
  } = useQuery({
    queryKey: ['rootFolders', selectedProject?.id, publishFolderPermission],
    queryFn: async ({ queryKey: [, projectId] }) =>
      cancellablePromise(
        getForgeApiServiceInstance().getFolders({ projectId: projectId!, permissionFilter: publishFolderPermission }),
      ),
    enabled: Boolean(selectedProject?.id),
  });

  useLogAndShowNotification(rootFoldersError, text.notificationGetRootFolderFailed);

  const handleUpdateDataStore = useCallback(
    (folder: MetaInfoPath) => {
      if (selectedAccount && selectedProject) {
        productDefinitionActions.setPublishLocation(selectedAccount, selectedProject, folder);
      }
    },
    [selectedAccount, selectedProject],
  );

  useEffect(() => {
    setSelectedFolderDropdownItem(undefined);
  }, [selectedProject?.id]);

  useEffect(() => {
    const foldersTreeDropdown: TreeItem[] =
      rootFolders?.map((folder) => ({
        id: folder.urn,
        value: folder.urn,
        label: folder.title,
        isExpandable: true,
        children: [],
        path: [],
        isRoot: folder.isRoot,
      })) || [];
    setRootFoldersDropdownItems(foldersTreeDropdown);
  }, [rootFolders]);

  const productNameErrors = validateProductName(productNameToPublish);

  const { productNameUniquenessErrorMessage, productNameUniquenessTooltipText } = useProductNameUniquenessValidation({
    projectId: selectedProject?.id,
    productName: productNameToPublish,
    selectedFolder: currentProductDefinition.folder,
    allProducts: products,
  });

  useEffect(() => {
    inventorStoreActions.setIsPublishingDisabled(
      !releaseNotesIsValid ||
        !selectedFolderTreeItem ||
        !productNameToPublish ||
        productNameErrors !== null ||
        isRootProjectFilesSelected ||
        !!productNameUniquenessErrorMessage ||
        isSelectedFolderAnActiveIncomingBridgeFolder,
    );
  }, [
    releaseNotesIsValid,
    selectedFolderTreeItem,
    productNameToPublish,
    productNameErrors,
    isRootProjectFilesSelected,
    productNameUniquenessErrorMessage,
    isSelectedFolderAnActiveIncomingBridgeFolder,
  ]);

  // wrapped with useCallback to prevent function re-creation and infinite loops for other standard react hooks which
  // depend on this function
  const handleSelectFolder = useCallback(
    (selectedFolder: TreeItem, path: MetaInfo[]) => {
      if (selectedFolder.id) {
        setSelectedFolderDropdownItem({
          id: selectedFolder.id,
          value: selectedFolder.id,
          label: selectedFolder.label,
          isExpandable: selectedFolder.isExpandable,
          isRoot: selectedFolder.isRoot,
        });

        const bimFolder: MetaInfoPath = {
          id: selectedFolder.id,
          name: selectedFolder.label.toString(),
          parentPath: path,
        };
        handleUpdateDataStore(bimFolder);

        // If the projects file is selected, we should disable publishing
        if (
          selectedFolder.label === text.projectFilesFolderName &&
          selectedFolder.isRoot &&
          !releaseNumberToBeLoading &&
          releaseNumberToBe &&
          enableAccBridge
        ) {
          // Exception: if a product already exits in the project files folder,
          // we should allow publishing of subsequent releases
          const existingProduct = products?.find((product) => product.name === productNameToPublish);
          if (existingProduct?.context.workspace.folderPath === getFullFolderPath(bimFolder) && releaseNumberToBe > 1) {
            setIsRootProjectFilesSelected(false);
          } else {
            setIsRootProjectFilesSelected(true);
          }
        } else {
          setIsRootProjectFilesSelected(false);
        }
      }
    },
    [handleUpdateDataStore, releaseNumberToBeLoading, enableAccBridge, products, releaseNumberToBe, productNameToPublish],
  );

  const handleNewProductDefinitionClick = () => {
    productDefinitionActions.resetProductDefinition();
    inventorStoreActions.setCurrentPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
  };

  const handleOpenProductDefinitionsSelectionClick = () => {
    productDefinitionActions.resetProductDefinition();
    inventorStoreActions.setCurrentPublishStatus(PublishStatus.IDLE);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_SELECTION);
  };

  const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductNameToPublish(event.target.value);
  };

  const handlePublishClick = async () => {
    const productAlreadyPublished = products?.find((product) => product.name === productNameToPublish);

    // Case : If the product with the same name already exists and user specified the same folder
    // note: this function wouldn't have been called if the user had selected a different folder
    // Actions : New release
    if (productAlreadyPublished) {
      const currentUploadLocation = productAlreadyPublished.context.workspace.folderPath;
      return handlePublishProductDefinition(true, productAlreadyPublished.contentId, currentUploadLocation);
    }

    // Otherwise, it is a totally new Product, so we POST new product
    handlePublishProductDefinition();
  };

  EventEmitter.unsubscribe(Events.PUBLISH);
  EventEmitter.subscribe(Events.PUBLISH, handlePublishClick);

  const handlePublishProductDefinition = async (
    isCreatingNewRelease = false,
    productIdToCreateNewRelease?: string,
    currentUploadLocation?: string,
  ): Promise<void> => {
    // If we are creating a new release,
    // We need to block the user from publishing to a new folder
    if (isCreatingNewRelease) {
      const newReleaseLocation = getFullFolderPath(folder);
      if (newReleaseLocation !== currentUploadLocation) {
        showNotification({
          message: text.notificationPublishProductDefinitionSameFolder,
          severity: NOTIFICATION_STATUSES.WARNING,
        });
        return;
      }
    }
    inventorStoreActions.setCurrentPublishStatus(PublishStatus.LOADING);

    // We save the currentProductDefinition regardless of the outcome of publish
    const upsertedProductDefinition = await ProductDefinitionsCrudUtils.upsertProductDefinition(currentProductDefinition);

    // Validate form rules before publishing
    if (upsertedProductDefinition.rules) {
      const formRules = upsertedProductDefinition.rules.find((rule) => formRulesKey === rule.key);
      if (formRules) {
        try {
          JSON.parse(formRules.code);
        } catch (e) {
          showNotification({
            message: text.notificationPublishProductDefinitionFailed,
            messageBody: text.publishFormRulesAreInvalid,
            severity: NOTIFICATION_STATUSES.ERROR,
          });
          inventorStoreActions.setCurrentPublishStatus(PublishStatus.FAILURE);
          return;
        }
      }
    }

    // We update the product inputs to exclude min/max/increment values, as the validation will be done in the front-end
    // Currently, the back-end validation clashes with the front-end validation.
    // In the future, we will have a validation based of the codeblocks rules in the back-end
    const inputs = upsertedProductDefinition.inputs.map((input) => {
      if (isNumericInput(input)) {
        const { min, max, increment, ...rest } = input;
        return rest;
      }
      return input;
    });

    const response = await publishProductDefinition(
      {
        ...upsertedProductDefinition,
        inputs,
        name: productNameToPublish,
        latestContentId: productIdToCreateNewRelease,
      },
      isCreatingNewRelease,
      enforceRevitClassification,
    );

    if (response.status.toLowerCase() === PublishStatus.COMPLETE) {
      const publishedProductDefinition = response.publishedProduct;

      // Amplitude Event
      if (publishedProductDefinition) {
        ampli.ivtwProductDefinitionPublish({
          projectId: publishedProductDefinition.tenancyId,
          productId: publishedProductDefinition.contentId,
          productName: publishedProductDefinition.name,
          numberOfInputs: publishedProductDefinition.inputs.length,
          hasRules: !!publishedProductDefinition.rulesKey,
          hasCodeBlocksWorkspace: !!publishedProductDefinition.codeBlocksWorkspaceKey,
          releaseNumber: publishedProductDefinition.release,
          numberOfOutputs: publishedProductDefinition.outputs.length,
          outputTypes: publishedProductDefinition.outputs.map((output) => output.type),
          accountId: selectedAccount?.id,
        });
      }

      inventorStoreActions.setCurrentPublishStatus(PublishStatus.COMPLETE);
      showNotification({
        message: text.notificationPublishProductDefinitionSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
      // If success, we save the release name of the Product Definition as well
      await ProductDefinitionsCrudUtils.upsertProductDefinition({
        ...upsertedProductDefinition,
        releaseName: productNameToPublish,
        latestContentId: response.publishedProduct?.contentId,
        // reset the release notes in case of successful publish
        notes: '',
      });
    } else if (response.status.toLowerCase() === PublishStatus.FAILURE) {
      showNotification({
        message: text.notificationPublishProductDefinitionFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      inventorStoreActions.setCurrentPublishStatus(PublishStatus.FAILURE);
    }

    setPublishResponse(response);
  };

  const handleProductNameDoubleClick = (product: DynamicContent) => {
    setProductNameToPublish(product.name);
  };

  const onReleaseNotesChange = (value: string, isValid: boolean) => {
    setReleaseNotesIsValid(isValid);

    if (isValid) {
      productDefinitionActions.setNotes(value);
    }
  };

  useEffect(() => {
    if (enableAccBridge) {
      if (isRootProjectFilesSelected) {
        return inventorStoreActions.setPublishingDisabledCause(text.publishingDisabledCause.projectFilesSelected);
      }

      // If the selected folder is an Active Incoming Bridge folder, we disable publishing
      if (isSelectedFolderAnActiveIncomingBridgeFolder) {
        return inventorStoreActions.setPublishingDisabledCause(
          text.publishingDisabledCause.selectedFolderIsIncomingBridgeFolder,
        );
      }

      inventorStoreActions.setPublishingDisabledCause(null);
    }
  }, [enableAccBridge, isRootProjectFilesSelected, isSelectedFolderAnActiveIncomingBridgeFolder]);

  return {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeItem,
    publishResponse,
    productNameToPublish,
    handleSelectFolder,
    handleNewProductDefinitionClick,
    handleOpenProductDefinitionsSelectionClick,
    handleProductNameChange,
    handleProductNameDoubleClick,
    handlePublishClick,
    products,
    productsLoading,
    incomingAccBridgeProducts: bridgeProducts,
    bridgeProductsLoading,
    productsError,
    publishFolderPermission,
    productNameErrors: productNameErrors || productNameUniquenessErrorMessage,
    productNameIconErrorText: productNameUniquenessTooltipText,
    releaseNumberToBe,
    shouldShowReleaseNumberToBe,
    onReleaseNotesChange,
    releaseNumberToBeLoading,
  };
};

export default usePublishing;
