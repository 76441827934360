import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';

export const BlocklyPlaceholder = styled('div')`
  width: 100%;
  height: 100%;
  & .blocklyMainBackground {
    stroke: none;
  }
`;

export const BlocklyWrapper = styled('div')`
  display: flex;
  height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`});
`;

export const PreviewPlaceholder = styled('div')`
  height: 100%;
`;

export const ControlsWrapper = styled('div')`
  display: flex;
  margin-inline: ${({ theme }) => `${theme.var.productFormHorizontalSpacing}px`};
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  height: ${({ theme }) => `${theme.var.formPreviewControlsHeight}px`};
`;

export const ProductCustomizationFormWrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isILogicAlertOpen',
})<{ isILogicAlertOpen: boolean }>`
  ${({ isILogicAlertOpen, theme }) =>
    isILogicAlertOpen
      ? `max-height: calc(100% - ${theme.var.inventorCustomizationFormHeaderHeight + theme.var.iLogicAlertHeight}px);`
      : `max-height: calc(100% - ${theme.var.inventorCustomizationFormHeaderHeight}px);`}
  overflow: auto;
  width: ${({ theme }) => {
    const formPadding = theme.var.productFormHorizontalSpacing;
    const formPaddingTwoSided = formPadding * 2;
    const formFieldWidth = theme.var.wideInputControl;
    const formIconWidth = theme.var.formNotificationIconSize;

    return formPaddingTwoSided + formFieldWidth + formIconWidth;
  }}px;
  margin: 0 ${({ theme }) => `${theme.var.marginBase}px`};
  display: flex;
  flex-direction: column;
`;

export const WorkspaceSelectorGroup = styled(ToggleButtonGroup)`
  position: absolute;
  z-index: 1;
  left: calc(50% - ${({ theme }) => theme.var.wideInputControl}px);
  background: ${({ theme }) => theme.palette.background.default};
  margin-top: ${({ theme }) => theme.var.paddingBase}px;
`;

export const AlertWrapper = styled(Alert)`
  border-radius: 0;
  border-left: ${({ theme }) => theme.var.iLogicAlertBorderLeft}px solid ${({ theme }) => theme.palette.info.main};
  background-color: ${({ theme }) => theme.palette.background.default};
`;
