import { NOTIFICATION_STATUSES, NotificationContext, useLogAndShowNotification } from '@mid-react-common/common';
import text from 'inventor.text.json';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductDefinition, ProductDefinitionsCrudUtils } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useState, useCallback, useEffect, useContext } from 'react';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import {
  getMigratedProductDefinitionMultiValueInputs,
  hasSomeProductDefinitionsWithDeprecatedInputs,
} from './migrateProductDefinitionsContainingDeprecatedInputs.utils';
import TabProgressContext from 'context/TabProgressStore/TabProgress.context';
import { ampli } from '../../ampli';
import { productDefinitionActions } from '../../context/DataStore/productDefinitionStore';
import { inventorStoreActions } from '../../context/DataStore/InventorDataStore';
import { insertDuplicatedProductionDefinitionIntoList } from './useProductDefinitionSelectionScreen.utils';
import { useQuery } from '@tanstack/react-query';

interface UseProductDefinitionScreenProps {
  productDefinitions: ProductDefinition[] | null;
  loading: boolean;
  error: Error | null;
  handleEditProductDefinition: (productDefinition: ProductDefinition) => void;
  handleDeleteProductDefinitions: (productDefinitionIds: string[]) => Promise<void>;
  handleDuplicateProductDefinition: (productDefinition: ProductDefinition) => Promise<void>;
  handleNewProductDefinitionClick: () => void;
}

const PRODUCT_DEFINITIONS_KEY = 'productDefinitions';

const useProductDefinitionSelectionScreen = (): UseProductDefinitionScreenProps => {
  const { enableMultiValuesBackwardsCompatibility } = useFlags();
  const { setCurrentScreen } = useContext(NavigationContext);
  const { resetToInitialActiveTab } = useContext(TabProgressContext);

  const { showNotification } = useContext(NotificationContext);
  const [productDefinitions, setProductDefinitions] = useState<ProductDefinition[] | null>(null);

  const {
    data: fetchedProductDefinitions,
    isPending: productDefinitionsLoading,
    error,
  } = useQuery({
    queryKey: [PRODUCT_DEFINITIONS_KEY],
    queryFn: () => ProductDefinitionsCrudUtils.getProductDefinitions(),
  });
  if (fetchedProductDefinitions?.length && !productDefinitions) {
    setProductDefinitions(fetchedProductDefinitions);
  }

  useLogAndShowNotification(error, text.notificationGetProductDefinitionsFailed);

  const handleEditProductDefinition = (productDefinition: ProductDefinition) => {
    resetToInitialActiveTab(); // Start at Source Content tab whenever we edit a product definition
    productDefinitionActions.setProductDefinition(productDefinition);
    inventorStoreActions.setAreDrawingFilesFetched(false);
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
    inventorStoreActions.setLatestWorkspaceSelected(undefined);
  };

  const handleDeleteProductDefinitions = async (productDefinitionIds: string[]): Promise<void> => {
    try {
      const currentProductDefinitions = await ProductDefinitionsCrudUtils.deleteProductDefinitions(
        productDefinitionIds,
        false,
      );

      // Amplitude Event
      ampli.ivtwProductDefinitionDelete({
        numberOfDeletedProductDefinitions: productDefinitionIds.length,
        productDefinitionIds,
      });

      setProductDefinitions(currentProductDefinitions);
      showNotification({
        message: text.notificationDeleteProductDefinitionSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err) {
      showNotification({
        message: text.notificationDeleteProductDefinitionFailed,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
      logError(err);
      return Promise.reject(err);
    }
  };

  const handleDuplicateProductDefinition = useCallback(
    async (productDefinition: ProductDefinition): Promise<void> => {
      if (!productDefinitions) {
        return;
      }

      const duplicatedProductDefinition = await ProductDefinitionsCrudUtils.duplicateProductDefinition({
        ...productDefinition,
        releaseName: '',
        latestContentId: undefined,
      });

      // Amplitude Event
      if (duplicatedProductDefinition.id) {
        ampli.ivtwProductDefinitionCreate({
          productDefinitionId: duplicatedProductDefinition.id,
          productDefinitionName: duplicatedProductDefinition.name,
          numberOfInputs: duplicatedProductDefinition.inputs.length,
          hasRules: duplicatedProductDefinition.rules.length > 0,
          hasCodeBlocksWorkspace: !!duplicatedProductDefinition.codeBlocksWorkspace,
          numberOfOutputs: duplicatedProductDefinition.outputs.length,
          numberOfDrawingThumbnails: duplicatedProductDefinition.drawingThumbnails?.length || 0,
          releaseName: duplicatedProductDefinition.releaseName,
          hasBeenDuplicated: true,
        });
      }

      const updatedProductDefinitionList = insertDuplicatedProductionDefinitionIntoList(
        productDefinition,
        duplicatedProductDefinition,
        productDefinitions,
      );

      setProductDefinitions(updatedProductDefinitionList);
      showNotification({
        message: text.notificationDuplicateProductDefinitionSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    },
    [productDefinitions, setProductDefinitions, showNotification],
  );

  // Should be used inside a `WithConfirmation` handler
  const handleNewProductDefinitionClick = () => {
    resetToInitialActiveTab();
    setCurrentScreen(Screens.PRODUCT_DEFINITION_CONFIGURATION);
    productDefinitionActions.resetProductDefinition();
    inventorStoreActions.setAreDrawingFilesFetched(false);
    inventorStoreActions.setLatestWorkspaceSelected(undefined);
  };

  useEffect(() => {
    if (productDefinitions && enableMultiValuesBackwardsCompatibility) {
      if (hasSomeProductDefinitionsWithDeprecatedInputs(productDefinitions)) {
        const migratedProductDefinitions = productDefinitions.map((productDefinition) =>
          getMigratedProductDefinitionMultiValueInputs(productDefinition),
        );
        setProductDefinitions(migratedProductDefinitions);
      }
    }
  }, [productDefinitions, showNotification, enableMultiValuesBackwardsCompatibility, setProductDefinitions]);

  return {
    productDefinitions,
    loading: productDefinitionsLoading,
    error,
    handleNewProductDefinitionClick,
    handleEditProductDefinition,
    handleDeleteProductDefinitions,
    handleDuplicateProductDefinition,
  };
};

export default useProductDefinitionSelectionScreen;
