export default {
  topFolderSelectButton: 'top-folder-select-button',
  ipjSelectButton: 'ipj-select-button',
  topAssemblySelectButton: 'top-assembly-select-button',
  publishProductName: 'publish-product-name',
  revitCategoryList: 'revit-category-list',
  revitFamilyName: 'revit-family-name',
  revitFamilyCategory: 'revit-family-category',
  revitClassificationTooltipLocked: 'revit-classification-tooltip-locked',
  revitClassificationTooltipError: 'revit-classification-tooltip-error',
  outputStatusPrefix: 'output-status',
  productDefinitionNameInput: 'product-definition-name-input',
  updateFormButtonTestId: 'update-form-button',
  blocklyInputsWorkspaceTestId: 'blockly-inputs-workspace',
  blocklyFormWorkspaceTestId: 'blockly-form-workspace',
  resetToDefaultsButtonTestId: 'reset-to-defaults',
  getModelValuesButtonTestId: 'get-model-values',
  setModelValuesButtonTestId: 'set-model-values',
  inputsFilter: 'inputs-filter',
  parametersButton: 'test-parameters-button-id',
  sidebarInput: 'sidebarInput',
  dragHandle: 'dragHandle',
  visibilityIcon: 'VisibilityIcon',
  parametersGroupButton: 'test-group-button-id',
  workspaceSelectorTestId: 'workspace-selector',
  formWorkspaceUpdateForm: 'form-workspace-update-form',
  workspaceSelectorInput: 'workspace-selector-input',
  workspaceSelectorForm: 'workspace-selector-form',
  releaseToBeContainer: 'release-to-be-container',
} as const;
