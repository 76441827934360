import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const PublishingHeaderContainer = styled('div')`
  height: ${({ theme }) => `${theme.var.publishHeaderHeight}px`};
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px ${theme.var.paddingBase * 4}px`};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PublishErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))`
  & .${tooltipClasses.tooltip} {
    padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
  }
`;
export const PublishErrorTooltipHeadline = styled(Typography)`
  padding-bottom: ${({ theme }) => `${theme.var.paddingBase}px`};
`;
