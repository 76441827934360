import text from 'inventor.text.json';
import { useEffect, useMemo, useState } from 'react';

export const InputHeaderCurrentFilterKeys = {
  parameters: 'parameters',
  properties: 'properties',
} as const;

export type InputHeaderCurrentFilter = {
  [InputHeaderCurrentFilterKeys.parameters]: string;
};

export interface UseInputsHeaderState {
  buttonGroupValue: string;
  currentFilter: InputHeaderCurrentFilter;
  setCurrentFilter: React.Dispatch<React.SetStateAction<InputHeaderCurrentFilter>>;
  filterItems: string[];
}

export const useInputsHeader = (): UseInputsHeaderState => {
  const [buttonGroupValue, setButtonGroupValue] = useState(text.inputsParameters);
  const [currentFilter, setCurrentFilter] = useState<InputHeaderCurrentFilter>({
    parameters: text.inputsFilterOptionUser,
  });
  const [filterItems, setFilterItems] = useState<string[]>([]);

  const parametersItems = useMemo(
    () => [
      text.inputsFilterOptionAll,
      text.inputsFilterOptionModel,
      text.inputsFilterOptionUser,
      text.inputsFilterOptionKey,
      text.inputsFilterOptionSelected,
    ],
    [],
  );

  // Set filter items based on isParameter
  useEffect(() => {
    setFilterItems(parametersItems);
    setButtonGroupValue(text.inputsParameters);
  }, [parametersItems]);

  return {
    buttonGroupValue,
    currentFilter,
    setCurrentFilter,
    filterItems,
  };
};
