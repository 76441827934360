import { ProductDefinitionOutput, RevitClassification, getRevitClassification } from 'mid-addin-lib';
import { OutputType } from '@adsk/offsite-dc-sdk';
import { useEffect } from 'react';
import { useLogAndShowNotification } from '@mid-react-common/common';
import text from 'inventor.text.json';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { productDefinitionActions, useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { useShallow } from 'zustand/react/shallow';

interface UseRevitClassificationState {
  isLoading: boolean;
  revitClassification: RevitClassification;
}

const REVIT_CLASSIFICATION_KEY = 'revitClassificationKey';

export const useRevitClassification = (): UseRevitClassificationState => {
  const outputs = useProductDefinitionStore(useShallow((state) => state.outputs));
  const { enforceRevitClassification } = useFlags();

  const currentProductDefinitionRevitFamilyOutput: ProductDefinitionOutput | undefined = outputs.find(
    (output) => output.type === OutputType.RFA,
  );

  const {
    data: revitClassification,
    isFetching: isLoading,
    error,
  } = useQuery({
    queryKey: [REVIT_CLASSIFICATION_KEY],
    queryFn: async () => getRevitClassification(),
    initialData: {
      behavior: '',
      category: currentProductDefinitionRevitFamilyOutput?.options?.category || '',
      familyName: currentProductDefinitionRevitFamilyOutput?.options?.family || '',
      familyTypeName: '',
      isValid: false,
    },
  });

  useLogAndShowNotification(error, text.failedToFetchRevitClassification);

  useEffect(() => {
    if (!error) {
      const updatedOptions: ProductDefinitionOutput['options'] = {
        family: revitClassification.familyName,
        category: revitClassification.category || '',
      };

      const needsUpdate =
        updatedOptions.family !== currentProductDefinitionRevitFamilyOutput?.options?.family ||
        updatedOptions.category !== currentProductDefinitionRevitFamilyOutput?.options?.category;

      if (needsUpdate) {
        if (enforceRevitClassification) {
          productDefinitionActions.addOutputOptions(OutputType.RFA, updatedOptions);
        }
      }
    }
  }, [
    currentProductDefinitionRevitFamilyOutput?.options?.category,
    currentProductDefinitionRevitFamilyOutput?.options?.family,
    enforceRevitClassification,
    error,
    revitClassification,
  ]);

  return {
    isLoading,
    revitClassification,
  };
};
