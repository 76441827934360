import Box from '@mui/material/Box';
import { LocationContainer, Spacing } from '@mid-react-common/common';
import text from 'inventor.text.json';
import Typography from '@mui/material/Typography';
import {
  codeBlocksBestPracticesLink,
  parameterAdoptionLink,
  publishingPrerequisitesChecksLink,
  syncingAndSavingLink,
} from './constants';
import ValidationSection from './ValidationSection';
import { useInventorDataStore } from 'context/DataStore/InventorDataStore';
import { PrerequisitesWrapper } from '../Publishing.styles';
import { useShallow } from 'zustand/react/shallow';
import { publishingInvalidtestIds } from 'tests/helpers/dataTestIds';

const publishValidationText = text.publishValidation;

const PublishingInvalid: React.FC = (): JSX.Element => {
  const { publishPrerequisiteErrors } = useInventorDataStore(
    useShallow((state) => ({
      publishPrerequisiteErrors: state.publishPrerequisiteErrors,
      productDefinitionHasUnsavedChanges: state.productDefinitionHasUnsavedChanges,
    })),
  );

  return (
    <LocationContainer className="mid-bg-shadow">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h2" fontWeight="bold" gutterBottom data-testid={publishingInvalidtestIds.incompleteReqsTitle}>
          {publishValidationText.resolveRequirements}
        </Typography>
        <Spacing />
        <PrerequisitesWrapper>
          <ValidationSection
            title={publishValidationText.formValuesCheck.title}
            description={publishValidationText.formValuesCheck.description}
            isComplete={!publishPrerequisiteErrors.formError}
            resources={[
              {
                label: publishValidationText.helpLink,
                url: publishingPrerequisitesChecksLink,
              },
              {
                label: publishValidationText.bestPracticesLink,
                url: codeBlocksBestPracticesLink,
              },
            ]}
          />
          <ValidationSection
            title={publishValidationText.productDefinitionDefaultsCheck.title}
            description={publishValidationText.productDefinitionDefaultsCheck.description}
            isComplete={!publishPrerequisiteErrors.productDefinitionDefaultValuesError}
            resources={[
              {
                label: publishValidationText.helpLink,
                url: publishingPrerequisitesChecksLink,
              },
              {
                label: publishValidationText.parameterAdoptionLink,
                url: parameterAdoptionLink,
              },
              {
                label: publishValidationText.bestPracticesLink,
                url: codeBlocksBestPracticesLink,
              },
            ]}
          />
          <ValidationSection
            title={publishValidationText.inventorModelValuesCheck.title}
            description={publishValidationText.inventorModelValuesCheck.description}
            isComplete={!publishPrerequisiteErrors.inventorModelDefaultValuesError}
            resources={[
              {
                label: publishValidationText.helpLink,
                url: publishingPrerequisitesChecksLink,
              },
              {
                label: publishValidationText.syncingAndSavingLink,
                url: syncingAndSavingLink,
              },
            ]}
          />
          <ValidationSection
            title={publishValidationText.inventorDataSetSavedCheck.title}
            description={publishValidationText.inventorDataSetSavedCheck.description}
            isComplete={!publishPrerequisiteErrors.documentSavedError}
            resolutionSteps={[
              publishValidationText.inventorDataSetSavedCheck.step1,
              publishValidationText.inventorDataSetSavedCheck.step2,
              publishValidationText.inventorDataSetSavedCheck.step3,
            ]}
          />
        </PrerequisitesWrapper>
      </Box>
    </LocationContainer>
  );
};

export default PublishingInvalid;
