import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { validateProductDefinitionName } from '../../../../utils/productDefinition';
import { productDefinitionActions, useProductDefinitionStore } from '../../../../context/DataStore/productDefinitionStore';
import { inventorStoreActions } from '../../../../context/DataStore/InventorDataStore';
import { useShallow } from 'zustand/react/shallow';
export const productDefinitionNameSaveDebounceTimeout = 500;

type UseProductDefinitionNameState = {
  productDefinitionNameError: string | null;
  handleProductDefinitionNameChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const useProductDefinitionName = (): UseProductDefinitionNameState => {
  const { productDefinitionName, productDefinitionId } = useProductDefinitionStore(
    useShallow((state) => ({
      productDefinitionName: state.name,
      productDefinitionId: state.id,
    })),
  );

  const [localProductDefinitionName, setLocalProductDefinitionName] = useState<string | undefined>(undefined);
  const [productDefinitionNameError, setProductDefinitionNameError] = useState<string | null>(null);

  const debouncedValidation = debounce((newName: string) => {
    setLocalProductDefinitionName(newName);
    productDefinitionActions.setName(newName);
  }, productDefinitionNameSaveDebounceTimeout);

  const handleProductDefinitionNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    debouncedValidation(newName);
  };

  useEffect(() => {
    if (productDefinitionName) {
      setLocalProductDefinitionName(productDefinitionName);
    }
  }, [productDefinitionName]);

  useEffect(() => {
    async function validateName(newName: string) {
      const validationError = await validateProductDefinitionName(newName, productDefinitionId);
      setProductDefinitionNameError(validationError.cause);
      inventorStoreActions.setIsErronousProductDefinitionName({
        error: validationError.error,
        cause: validationError.cause,
      });
    }
    if (localProductDefinitionName !== undefined) {
      validateName(localProductDefinitionName);
    }
  }, [productDefinitionId, localProductDefinitionName]);

  return {
    productDefinitionNameError,
    handleProductDefinitionNameChange,
  };
};
