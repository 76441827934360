import isUndefined from 'lodash/isUndefined';
import { ProductDefinition } from 'mid-addin-lib';

export const insertDuplicatedProductionDefinitionIntoList = (
  originalProductDefinition: ProductDefinition,
  duplicatedProductDefinition: ProductDefinition,
  productDefinitions: ProductDefinition[],
): ProductDefinition[] => {
  const indexOfOriginalProductDefinition = productDefinitions.findIndex((d) => d.name === originalProductDefinition.name);

  let updatedProductDefinitionList;
  if (productDefinitions && !isUndefined(indexOfOriginalProductDefinition) && indexOfOriginalProductDefinition >= 0) {
    updatedProductDefinitionList = [...productDefinitions];
    // Insert duplicated definition below the one that was copied
    updatedProductDefinitionList.splice(indexOfOriginalProductDefinition + 1, 0, duplicatedProductDefinition);
  } else {
    updatedProductDefinitionList = [...productDefinitions, duplicatedProductDefinition];
  }

  return updatedProductDefinitionList;
};
