import LaunchIcon from '@mui/icons-material/Launch';
import text from 'inventor.text.json';
import { LeftSection, RightButton, RightSection, Title } from '@mid-react-common/common';
import React from 'react';

const Initial: React.FC<{
  handleMidWebPortalClick: () => void;
}> = ({ handleMidWebPortalClick }) => (
  <>
    <LeftSection>
      <Title variant="h1">{text.subHeaderTitleInitial}</Title>
    </LeftSection>
    <RightSection>
      <RightButton endIcon={<LaunchIcon />} onClick={handleMidWebPortalClick} size="small" variant="outlined">
        {text.buttonGoToMidWebPortal}
      </RightButton>
    </RightSection>
  </>
);

export default Initial;
