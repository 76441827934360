import {
  PrintMessageDialog,
  ProductCustomizationForm,
  productCustomizationTestIds,
  usePrintMessageDialog,
} from '@mid-react-common/addins';
import { StateSetter } from '@mid-react-common/common';
import { ErrorCode } from 'mid-utils';
import { AlertWrapper, ControlsWrapper, PreviewPlaceholder, ProductCustomizationFormWrapper } from '../Rules.styles';
import { CodeblocksWorkspaceType } from '../types';
import { FormWorkspaceControls } from './FormWorkspaceControls';
import { InputWorkspaceControls } from './InputWorkspaceControls';
import { useProductFormPreview } from './useProductFormPreview';
import { useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { MutableRefObject } from 'react';
import { useShallow } from 'zustand/react/shallow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import text from 'inventor.text.json';
import { iLogicBestPracticesUrl } from '../constants';
import { inventorStoreActions, useInventorDataStore } from 'context/DataStore/InventorDataStore';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { productCustomizationFormWrapper } = productCustomizationTestIds;

interface ProductFormPreviewProps {
  updateFormEnabled: boolean;
  setUpdateFormEnabled: StateSetter<boolean>;
  selectedWorkspace: CodeblocksWorkspaceType;
  setHighlightedBlockId: (highlightedBlockId: string | undefined) => void;
  areRulesLoaded: boolean;
  getCodeRef: MutableRefObject<(() => string) | undefined>;
}

export const ProductFormPreview: React.FC<ProductFormPreviewProps> = ({
  setUpdateFormEnabled,
  updateFormEnabled,
  selectedWorkspace,
  setHighlightedBlockId,
  areRulesLoaded,
  getCodeRef,
}): JSX.Element => {
  const theme = useTheme();
  const { enablePublishChecks } = useFlags();
  const inputs = useProductDefinitionStore(useShallow((state) => state.inputs));
  const numberOfProductDefinitions = useInventorDataStore((state) => state.numberOfProductDefinitions);
  const isProductFormPreviewILogicAlertOpen = useInventorDataStore(
    useShallow((state) => state.isProductFormPreviewILogicAlertOpen),
  );
  const { isMessageDialogOpen, dialogMessage, showMessageDialog, closeMessageDialog } = usePrintMessageDialog();
  const {
    handleUpdateForm,
    handleSetModelValues,
    handleResetToDefaults,
    handleGetModelValues,
    handleInputUpdate,
    inputsError,
    currentFormRules,
    isFormLoading,
  } = useProductFormPreview({
    showMessageDialog,
    setUpdateFormEnabled,
    setHighlightedBlockId,
    areRulesLoaded,
    getCodeRef,
  });

  // the error might come from both CodeRunner or
  // from the custom .error prop from the Product Definition Input or
  // some invalid value type in some input
  const hasInputsError = inputs.some((input) => input.error) || inputsError?.errorCode === ErrorCode.CodeRunnerError;

  const handlAlertOnClose = () => {
    inventorStoreActions.setIsProductFormPreviewILogicAlertOpen(false);
  };

  return (
    <PreviewPlaceholder className="mid-bg-shadow">
      {enablePublishChecks && isProductFormPreviewILogicAlertOpen && numberOfProductDefinitions < 5 && (
        <AlertWrapper severity="info" onClose={handlAlertOnClose}>
          <Box marginLeft={`${theme.var.marginBase}px`}>
            <Typography fontWeight="bolder">{text.checkILogic}</Typography>
            <Link underline="none" href={iLogicBestPracticesUrl} target="_blank">
              {text.iLogicBestPractices}
            </Link>
          </Box>
        </AlertWrapper>
      )}
      <ControlsWrapper>
        {selectedWorkspace === CodeblocksWorkspaceType.INPUT && (
          <InputWorkspaceControls
            handleUpdateForm={handleUpdateForm}
            handleSetModelValues={handleSetModelValues}
            handleResetToDefaults={handleResetToDefaults}
            handleGetModelValues={handleGetModelValues}
            updateFormEnabled={updateFormEnabled}
            hasInputsError={hasInputsError}
          />
        )}
        {selectedWorkspace === CodeblocksWorkspaceType.FORM && <FormWorkspaceControls handleUpdateForm={handleUpdateForm} />}
      </ControlsWrapper>
      <ProductCustomizationFormWrapper
        data-testid={productCustomizationFormWrapper}
        isILogicAlertOpen={isProductFormPreviewILogicAlertOpen}
      >
        <ProductCustomizationForm
          inputs={inputs}
          inputsError={inputsError}
          handleInputUpdate={handleInputUpdate}
          formLayoutRules={currentFormRules}
          isFormLoading={isFormLoading}
        />
      </ProductCustomizationFormWrapper>
      <PrintMessageDialog isMessageDialogOpen={isMessageDialogOpen} closeMessageDialog={closeMessageDialog} isAddin>
        {dialogMessage}
      </PrintMessageDialog>
    </PreviewPlaceholder>
  );
};
