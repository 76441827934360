import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import text from 'inventor.text.json';
import { Hyperlink, LeftSection, RightButton, RightSection, SpinnerForButton, Title } from '@mid-react-common/common';
import React, { useState } from 'react';
import { publishTestIds } from 'tests/helpers/dataTestIds';
import { accBridgeLink, EventEmitter } from 'mid-utils';
import Events from '../../../utils/eventEmitterEvents';
import { useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { useInventorDataStore } from '../../../context/DataStore/InventorDataStore';
import IconButton from '@mui/material/IconButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Link from '@mui/material/Link';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PublishErrorTooltip, PublishErrorTooltipHeadline } from './PublishingHeader.styles';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from '@mui/material/styles';

interface PublishLocationScreenHeaderProps {
  handleEditProductDefinitionClick: () => void;
  handleOpenProductDefinitionsSelectionClick: () => void;
  showPublishButton?: boolean;
}

export const PublishLocationScreenHeader: React.FC<PublishLocationScreenHeaderProps> = ({
  handleEditProductDefinitionClick,
  handleOpenProductDefinitionsSelectionClick,
  showPublishButton,
}) => {
  const productDefinitionName = useProductDefinitionStore((state) => state.name);
  const { isPublishingDisabled, publishingDisabledCause } = useInventorDataStore(
    useShallow((state) => ({
      isPublishingDisabled: state.isPublishingDisabled,
      publishingDisabledCause: state.publishingDisabledCause,
    })),
  );
  const theme = useTheme();
  const { enableAccBridge } = useFlags();

  const [isLoading, setIsLoading] = useState(false);
  const handlePublishClick = () => {
    setIsLoading(true);
    EventEmitter.dispatch(Events.PUBLISH);
  };

  return (
    <>
      <LeftSection>
        <Title variant="h1">
          <Hyperlink component="button" onClick={handleOpenProductDefinitionsSelectionClick}>
            {text.buttonProductDefinitions}
          </Hyperlink>
          {' / '}
          {productDefinitionName}
        </Title>
      </LeftSection>
      <RightSection>
        <RightButton
          onClick={handleEditProductDefinitionClick}
          size="small"
          variant="outlined"
          startIcon={<KeyboardBackspaceOutlinedIcon />}
        >
          {text.buttonEditProductDefinition}
        </RightButton>
        {showPublishButton && (
          <>
            <RightButton
              size="small"
              variant="contained"
              disabled={isPublishingDisabled}
              onClick={handlePublishClick}
              data-testid={publishTestIds.publishButton}
            >
              {text.buttonPublish}
              {isLoading && <SpinnerForButton size={theme.var.smallButtonSpinnerSize} color="inherit" />}
            </RightButton>
            {isPublishingDisabled && publishingDisabledCause && enableAccBridge && (
              <PublishErrorTooltip
                title={
                  <>
                    <PublishErrorTooltipHeadline>{publishingDisabledCause}</PublishErrorTooltipHeadline>
                    <Link
                      className="mid-tooltip-link"
                      underline="none"
                      href={accBridgeLink}
                      variant="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text.informedDesignAndACCBridge}
                    </Link>
                  </>
                }
                data-testid={publishTestIds.publishWarningIcon}
              >
                <IconButton>
                  <WarningAmberIcon color="error" />
                </IconButton>
              </PublishErrorTooltip>
            )}
          </>
        )}
      </RightSection>
    </>
  );
};
