import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const ProductDefinitionTableHeader = styled('div')`
  margin: ${({ theme }) => `${theme.var.marginBase}px`} ${({ theme }) => `${theme.var.marginBase * 1.5}px`};
  display: flex;
`;

export const TableHeaderButtonsWrapper = styled('div')`
  margin-left: auto;
`;

export const TableHeaderButton = styled(Button)`
  margin: 0 0 0 ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ProductDefinitionSelectionTable = styled(DataGrid)`
  height: calc(
    100vh - ${({ theme }) => `${theme.var.headerHeight}px`} -
      ${({ theme }) => `${theme.var.productDefinitionTableHeaderHeight}px`}
  );
`;

export const Wrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ProductDefinitionListTableCellWrapper = styled('span')`
  text-overflow: ellipsis;
  overflow: hidden;
`;
