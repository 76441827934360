import React, { useContext } from 'react';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import { ProductDefinitionConfigurationScreen } from '../../screens/ProductDefinitionConfigurationScreen/ProductDefinitionConfigurationScreen';
import { ProductDefinitionSelectionScreen } from '../../screens/ProductDefinitionSelectionScreen/ProductDefinitionSelectionScreen';
import { Publishing } from '../Publishing/Publishing';

export const ScreenManager: React.FC = (props) => {
  const { currentScreen } = useContext(NavigationContext);

  if (currentScreen === Screens.PRODUCT_DEFINITION_SELECTION) {
    return <ProductDefinitionSelectionScreen {...props} />;
  }
  if (currentScreen === Screens.PRODUCT_DEFINITION_CONFIGURATION) {
    return <ProductDefinitionConfigurationScreen {...props} />;
  }
  if (currentScreen === Screens.PUBLISHING) {
    return <Publishing {...props} />;
  }
  return <ProductDefinitionSelectionScreen {...props} />;
};
