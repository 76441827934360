import { InputRule, ProductDefinitionInputParameter, ProductDefinitionOutput } from 'mid-addin-lib';
import { useEffect, useState } from 'react';
import { TabProgress } from '../../types';
import {
  updateOutputsProgressState,
  updateParametersProgressState,
  updateRulesProgressState,
  updateSourceContentProgressState,
} from './tabProgressStore.utils';

export enum TABS {
  SOURCE_CONTENT = 'SOURCE_CONTENT',
  INPUTS = 'INPUTS',
  RULES = 'RULES',
  OUTPUTS = 'OUTPUTS',
}

export interface TabProgressStore {
  tabProgress: {
    [TABS.SOURCE_CONTENT]: TabProgress;
    [TABS.INPUTS]: TabProgress;
    [TABS.RULES]: TabProgress;
    [TABS.OUTPUTS]: TabProgress;
  };
  activeTab: TABS;
  changeTab: (value: TABS) => void;
  handleTabRedirect: (value: TABS) => void;
  resetToInitialActiveTab: () => void;
  setHasInputsError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UseTabProgressStore {
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
  inputs: ProductDefinitionInputParameter[];
  outputs: ProductDefinitionOutput[];
  rules: InputRule[];
  recentlyAdoptedInputs: ProductDefinitionInputParameter[];
}

export const useTabProgressStore = ({
  topLevelFolder,
  inventorProject,
  assembly,
  inputs,
  outputs,
  rules,
  recentlyAdoptedInputs,
}: UseTabProgressStore): TabProgressStore => {
  const [sourceContentProgress, setSourceContentProgress] = useState<TabProgress>(TabProgress.EMPTY);
  const [inputsProgress, setInputsProgress] = useState<TabProgress>(TabProgress.EMPTY);
  const [rulesProgress, setRulesProgress] = useState<TabProgress>(TabProgress.EMPTY);
  const [outputsProgress, setOutputsProgress] = useState<TabProgress>(TabProgress.EMPTY);
  const initialActiveTab = TABS.SOURCE_CONTENT;
  const [activeTab, setActiveTab] = useState<TABS>(initialActiveTab);
  const [hasInputsError, setHasInputsError] = useState<boolean>(false);

  const changeTab = (value: TABS): void => {
    if (activeTab !== value) {
      setActiveTab(value);
    }
  };

  const handleTabRedirect = (value: TABS): void => {
    changeTab(value);
  };

  const resetToInitialActiveTab = (): void => {
    setActiveTab(initialActiveTab);
  };

  // SourceContent Progress State
  useEffect(() => {
    const sourceContentProgressState = updateSourceContentProgressState({
      topLevelFolder,
      inventorProject,
      assembly,
    });
    setSourceContentProgress(sourceContentProgressState);
  }, [topLevelFolder, inventorProject, assembly]);

  // Parameters Progress State
  useEffect(() => {
    const parametersProgressState = updateParametersProgressState(inputs);
    setInputsProgress(parametersProgressState);
  }, [inputs]);

  useEffect(() => {
    const rulesProgressState = updateRulesProgressState(rules, inputs, hasInputsError, recentlyAdoptedInputs);
    setRulesProgress(rulesProgressState);
  }, [rules, inputs, hasInputsError, recentlyAdoptedInputs]);

  // Outputs Progress State
  useEffect(() => {
    const outputsProgressState = updateOutputsProgressState(outputs);
    setOutputsProgress(outputsProgressState);
  }, [outputs]);

  return {
    tabProgress: {
      [TABS.SOURCE_CONTENT]: sourceContentProgress,
      [TABS.INPUTS]: inputsProgress,
      [TABS.RULES]: rulesProgress,
      [TABS.OUTPUTS]: outputsProgress,
    },
    activeTab,
    changeTab,
    resetToInitialActiveTab,
    handleTabRedirect,
    setHasInputsError,
  };
};
