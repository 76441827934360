import ToggleButton from '@mui/material/ToggleButton';
import { CodeblocksWorkspaceType } from '../types';
import { WorkspaceSelectorGroup } from '../Rules.styles';
import text from 'inventor.text.json';
import testIds from '../../../inventor.testids';

interface WorkspaceSelectorProps {
  selectedWorkspace: CodeblocksWorkspaceType;
  setSelectedWorkspace: (workspace: CodeblocksWorkspaceType) => void;
}
const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({ selectedWorkspace, setSelectedWorkspace }): JSX.Element => {
  const handleWorkspaceChange = (event: React.MouseEvent<HTMLElement>, newSelectedWorkspace: CodeblocksWorkspaceType) => {
    if (newSelectedWorkspace !== null) {
      setSelectedWorkspace(newSelectedWorkspace);
    }
  };

  return (
    <WorkspaceSelectorGroup
      exclusive
      size="small"
      value={selectedWorkspace}
      onChange={handleWorkspaceChange}
      data-testid={testIds.workspaceSelectorTestId}
    >
      <ToggleButton color="primary" value={CodeblocksWorkspaceType.FORM} data-testid={testIds.workspaceSelectorForm}>
        {text.blocklyForm}
      </ToggleButton>
      <ToggleButton color="primary" value={CodeblocksWorkspaceType.INPUT} data-testid={testIds.workspaceSelectorInput}>
        {text.blocklyInput}
      </ToggleButton>
    </WorkspaceSelectorGroup>
  );
};

export default WorkspaceSelector;
