import React from 'react';
import text from 'inventor.text.json';
import {
  Hyperlink,
  LeftSection,
  RightButton,
  RightSection,
  SpinnerForButton,
  Title,
  midTheme as theme,
} from '@mid-react-common/common';
import { useProductDefinitionConfigurationHeader } from './useProductDefinitionConfigurationHeader';
import { HeaderTestIds } from '../../../tests/helpers/dataTestIds';
import Tooltip from '@mui/material/Tooltip';
import { useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { useInventorDataStore } from '../../../context/DataStore/InventorDataStore';

const ProductDefinitionConfigurationHeader: React.FC<{
  isValidatingPublish: boolean;
  handleOpenProductDefinitionsSelectionClick: () => void;
  handlePublishClick: () => void;
}> = ({ isValidatingPublish, handleOpenProductDefinitionsSelectionClick, handlePublishClick }): JSX.Element => {
  const productDefinitionName = useProductDefinitionStore((state) => state.name);
  const isErronousProductDefinitionName = useInventorDataStore((state) => state.isErronousProductDefinitionName);
  const { readyToPublish, handleSaveProductDefinition, saveProductDefinitionLoading } =
    useProductDefinitionConfigurationHeader();

  return (
    <>
      <LeftSection>
        <Title variant="h1">
          <Hyperlink
            data-testid={HeaderTestIds.productDefinitionsLink}
            component="button"
            onClick={handleOpenProductDefinitionsSelectionClick}
          >
            {text.buttonProductDefinitions}
          </Hyperlink>
          {productDefinitionName && ` / ${productDefinitionName}`}
        </Title>
      </LeftSection>
      <RightSection>
        <Tooltip
          placement="bottom-start"
          title={isErronousProductDefinitionName.error && isErronousProductDefinitionName.cause}
          arrow
        >
          <span>
            <RightButton
              size="small"
              onClick={handleSaveProductDefinition}
              variant="outlined"
              data-testid={HeaderTestIds.saveButton}
              disabled={isErronousProductDefinitionName.error}
            >
              {text.buttonSave}
              {saveProductDefinitionLoading && <SpinnerForButton size={theme.var.buttonSpinnerSize} color="inherit" />}
            </RightButton>
          </span>
        </Tooltip>
        <RightButton
          size="small"
          disabled={!readyToPublish}
          onClick={handlePublishClick}
          data-testid={HeaderTestIds.publishButton}
          variant="contained"
        >
          {text.buttonPublish}
          {isValidatingPublish && <SpinnerForButton size={theme.var.smallButtonSpinnerSize} color="inherit" />}
        </RightButton>
      </RightSection>
    </>
  );
};

export default ProductDefinitionConfigurationHeader;
